//BLOG TEASER
.blog-teaser{
    position: relative;
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    width: 100%;
    display: block;
}
.blog-teaser:hover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);

    .blog-teaser__img{
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}

@media (max-width: 767px){
    .blog-teaser__xs-ratio {
        position: relative;
        width: 100%;
    }
    .blog-teaser__xs-ratio:before {
        display: block;
        content: ' ';
        padding-top: 100% !important;
    }
}

.blog-teaser__img{
    display: block;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}
.blog-teaser__img-container:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .2;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
}
.blog-teaser__body{
    position: absolute;
    left: 30px;
    bottom: 30px;
    right: 20px;
    z-index: 2;
}
.blog-teaser__body-label,
.blog-teaser__body-label a{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    font-family: var(--font-default-bold);
    color: #fff;
    text-decoration: none;
}
.blog-teaser__date {
    z-index: 5;
    position: absolute;
    right: 0;
    top: 0;
    background:var(--color-dark);
    color: #fff;
    padding: .5rem;
    font-size: .625rem;
    text-align:center;
    text-transform: uppercase;
    text-decoration:none;
}
.blog-teaser__date-day {
    font-family: var(--font-default-bold);
    font-size: 1.25rem;
    line-height:1.33;
    text-transform: uppercase;
}

/* anpassungen blog detailseite aufgrund alter contents */
.blog-detail .wysiwyg p,
.blog-detail .wysiwyg div{
    margin-left:0 !important;
}
.blog-detail .wysiwyg img,
.blog-detail .wysiwyg h1,
.blog-detail .wysiwyg h2,
.blog-detail .wysiwyg h3,
.blog-detail .wysiwyg h4,
.blog-detail .wysiwyg h5,
.blog-detail .wysiwyg h6 {
    margin-top:1.5rem;
    margin-left:0 !important;
}