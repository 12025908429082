/* nav level1 */
.product-nav {
    list-style: none;
    padding:calc(30rem/16);
    border-bottom:1px solid var(--color-mid-grey);
    display:none;
    @media (min-width: 768px){
        display:block;
        margin-top:2rem;
        padding:0;
        border:0;
    }
}

.product-nav__item {
    display:inline-block;
    padding:0;
    margin:0 .75rem 1rem 0;
    @media (min-width: 768px) {
        margin:0 1.5rem .75rem 0;
    }
}
.product-nav__link {
    font-family: var(--font-default-bold);
    font-weight: normal;
    padding:.375rem 0;
    color: var(--color-dark-grey);
    border-bottom:.25rem solid transparent;
    font-size:1rem;
}
.product-nav__link.active,
.product-nav__link:hover,
.product-nav__link:focus{
    color: var(--color-text-default);
    border-bottom:calc(3rem/16) solid var(--color-primary);
}


/* nav level 2 and 3 */
.product-nav-level-two,
.product-nav-level-three{
    list-style: none;
    padding:0;
    margin:0;
}
.product-nav-level-two__item,
.product-nav-level-three__item {
    display:inline-block;
    width:100%;
    padding:0;
    margin:0;
    @media (min-width: 768px) and (max-width: 1399px) {
        width:30%;
    }
    @media (min-width: 1400px) {
        width:19%;
    }
}
.product-nav-level-two__link,
.product-nav-level-three__link {
    position: relative;
    display:inline-block;
    margin:.25rem 0;
    padding:.25rem 1rem .25rem 2.375rem;
    color: var(--color-dark-grey);
    font-size:1rem;
}
.product-nav-level-two__link:before,
.product-nav-level-three__link:before {
    content:'';
    display: block;
    width:calc(26rem/16);
    height:calc(26rem/16);
    border:1px solid var(--color-dark-grey);
    border-radius:100%;
    position: absolute;
    left:0;
    top:.25rem;
}
.product-nav-level-two__link.active,
.product-nav-level-two__link:hover,
.product-nav-level-two__link:focus,
.product-nav-level-three__link.active,
.product-nav-level-three__link:hover,
.product-nav-level-three__link:focus{
    color: var(--color-text-default);
}
.product-nav-level-two__link.active:after,
.product-nav-level-three__link.active:after{
    content:'';
    display: block;
    width:calc(12rem/16);
    height:calc(12rem/16);
    background: var(--color-primary);
    border-radius:100%;
    position: absolute;
    left:calc(7rem/16);
    top:calc(11rem/16);
}
/* level 3 adaptions */
.product-nav-level-three__link {
    padding:.25rem 1rem .25rem 2rem;
    font-size:calc(14rem/16);
    @media (min-width: 768px){
        font-size:calc(15rem/16);
    }
}
.product-nav-level-three__link:before {
    width:calc(24rem/16);
    height:calc(24rem/16);
    left:0;
    top:.25rem;
}
.product-nav-level-three__link.active:after{
    width:calc(10rem/16);
    height:calc(10rem/16);
    left:calc(7rem/16);
    top:calc(11rem/16);
}




/* filter */
.product-filter {
    @media (min-width: 768px){
        margin:.75rem 0;
        border-radius:var(--border-radius);
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
        overflow: hidden;
        background-color: #fff;
    }
}
.product-grid-quick-filter__headline:not([hidden]) {
    @media (min-width: 768px){
        display:inline-block;
    }
}

@media (min-width: 768px){
    .product-filter__item {
        padding:calc(18rem/16) calc(24rem/16) calc(26rem/16) calc(24rem/16);
    }
    .product-filter__item .form-group {
        margin:0;
    }
    .product-filter__item + .product-filter__item {
        border-top:1px solid var(--color-mid-grey);
    }
}

/* grid */
.product-grid {
    margin:calc(30rem/16) 0;
}



