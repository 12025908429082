@media  (max-width: 767px) {
    .items-scrollable {
        position: relative;
        display: inline-block;
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding:0;
        white-space: nowrap;
        width: 100%;
    }
    .items-scrollable > li:last-child{
        margin-right:3rem;
    }
    .items-scrollable__container {
        position: relative;
    }
    .items-scrollable__container:after {
        pointer-events: none;
        content:'';
        display: block;
        height:auto;
        width:4rem;
        position: absolute;
        right: -.25rem;
        top:0;
        bottom:0;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }
    .main-content--cockpit .items-scrollable__container:after,
    .bg-light-grey .items-scrollable__container:after {
        background: linear-gradient(90deg, rgba(244,244,245,0) 0%, rgba(244,244,245,1) 100%);
    }
    .items-scrollable > .scroll-item {
        display: inline-block;
        flex:none;
        margin-bottom:-1px;
    }
}