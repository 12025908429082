.pdp-area--highlight {
    background-color: var(--color-light-grey);

    @media (max-width: 767px) {
        margin:0 -2.25rem;
        width:auto;
        padding:2rem 2.25rem;
    }

    @media (min-width: 768px) {
        padding:3rem 4.5rem;
    }

    @media (min-width: 1600px) {
        margin-right: calc(((calc(1620rem/16) - 100vw) / 2) - calc(20rem/16)); //container max-width - width/2 - padding
        padding-right: calc(calc(((calc(1620rem/16) - 100vw) / 2) - calc(20rem/16)) * -1) !important;
    }
}

@media (min-width: 768px) {
    .pdp-area {
        overflow:clip;
    }
    .pdp-sidebar {
        padding-left:4.5rem;
    }
    .pdp-sidebar h1, pdp-sidebar .h1 {
        font-size:1.875rem;
        margin-top:.5rem;
    }
}


/* slider */
.pdp-sticky{
    position: sticky;
    top: 210px;
    margin-bottom: calc(48rem/16);
}

/* categories */
.pdp-category__item{
    border: 2px solid var(--color-light-grey);
    padding: calc(8rem/16) calc(15rem/16) calc(12rem/16);
    border-radius: var(--border-radius);
}

/* certificates */
.certificate-list .list-inline-item:not(:last-child) {
    margin-bottom: calc(16rem/16);
}
.certificate-list__item{
    position: relative;
    min-width: calc(90rem/16);
}
.certificate-list__item-label{
    position: absolute;
    left: 0;
    line-height: calc(10/12);
    font-size: calc(10rem/16);
    margin-top: calc(-40rem/16);
}
.popover.certificate-popover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    height: auto;
    width: auto;
    max-width: calc(350rem/16);

    @media (max-width: 767px) {
        width: calc(100% - 5rem);
        margin-left:-1rem !important;
        min-height: auto;
    }
}
.certificate-popover .popover-body{
    padding: calc(20rem/16);
}

/* price */
.pdp-price-container{
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;

        margin-right: calc(24rem/16);
    }
}
.pdp-price{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 1200px) {
        flex-direction: row;
        align-items: center;
    }
}
.pdp-strike-price__info{
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
}
.pdp-online-price__container{
    padding: calc(24rem/16);
    height: 100%;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    border-radius: var(--border-radius);
}

/*status*/
.pdp-item-status{
    position: relative;
    white-space: nowrap;
    padding-left: calc(20rem/16);
    color: var(--color-active)
}
.pdp-item-status__dot {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: .6875rem;
    height: .6875rem;
    border-radius: 50%;
    background: var(--color-active);
}
.pdp-item-status--inactive,
.pdp-item__inactive{
    color: var(--color-inactive);
}
.pdp-item-status--inactive .pdp-item-status__dot{
    background: var(--color-inactive);
}

/* advantaages */
.advantages {
    text-align:center;
    margin-top:1rem;
    padding:0 .875rem;
}

.advantages__icon {
    font-size: 2rem;
    color:var(--color-primary);
    @media (min-width: 768px) {
        font-size: 3.125rem;
    }
}

.advantages .btn-icon{
    padding: calc(10rem/16);
    font-size: calc(10rem/16);

    & .icon{
        font-size: calc(10rem/16);
    }
}
.advantages .btn-icon.is-open .icon{
    transform: rotate(45deg);
}

.popover.advantages-popover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    height: auto;
    overflow: hidden;
    max-width: calc(750rem/16);


    @media (min-width: 1200px) {
        max-width: calc(1100rem/16);
    }

    @media (max-width: 767px) {
        width: calc(100% - 5rem);
        margin-left:-1rem !important;
        min-height: auto;
    }
}
.advantages-popover .popover-body{
    padding: calc(30rem/16);

    @media (min-width: 1200px){
        padding: calc(50rem/16) calc(50rem/16) calc(50rem/16) calc(100rem/16);
    }
}
.advantages-popup__btn{
    position: absolute;
    top: -30px;
    right: -30px;

    @media (min-width: 1200px){
        top: -50px;
        right: -50px;
    }
}


/* pdp accessory slider */
@media (max-width: 767px) {
    .pdp-container--slider{
        display: flex;
        flex-wrap: wrap;
        padding: calc(20rem/16) calc(10rem/16) calc(20rem/16) calc(20rem/16);
    }
}

.pdp-info__container{
    max-width: 50%
}