.side-nav {
    position: fixed;
    transform:translateX(110%);
    transition: all .3s;
    top:var(--header-height);
    right:0;
    height:100%;
    width:calc(540rem / 16);
    background: #fff;
    z-index:9999;
    overflow-x:hidden;
    overflow-y:auto;
    opacity: 0;
    @media (max-width: 767px) {
        width:90%;
        top:var(--header-height-xs);
    }
}
.side-nav-backdrop {
    transition: opacity .3s;
    opacity: 0;
}

.side-nav-backdrop.open {
    position: fixed;
    top:var(--header-height);
    right:0;
    bottom: 0;
    left:0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,.5);
    z-index:990;
    opacity: 1;
    @media (max-width: 767px) {
        top:var(--header-height-xs);
    }
}
.side-nav--cart {
    @media (min-width: 992px) {
        width:calc(900rem / 16);
    }
}
.side-nav.open {
    transform: translateX(0);
    opacity: 1;
}
.side-nav__level {
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    width:100%;
    background: #fff;
    transform: translateX(calc(550rem/16));
    transition: all .3s;
    overflow: hidden;
    opacity: 0;
}
.side-nav__level--main,
.side-nav__level.open {
    opacity: 1;
    overflow-x: hidden;
    transform: translateX(0);
}
.side-nav__scroll {
    overflow-y: scroll;
    height: calc(100% - var(--sidenav-top-height) - var(--header-height));
    @media (max-width: 767px) {
        height: calc(100% - var(--sidenav-top-height-xs) - var(--header-height-xs));
    }
}
.side-nav__scroll--main {
    height: calc(100% - var(--header-height));
    @media (max-width: 767px) {
        height: calc(100% - var(--header-height-xs));
    }
}

.side-nav__top {
    position: relative;
    border-bottom:.25rem solid var(--color-light-grey);
    overflow: hidden;
    height:var(--sidenav-top-height);
    @media (max-width: 767px) {
        height:var(--sidenav-top-height-xs);
    }
}
.side-nav__top-col {
    padding-top:calc(16rem/16);
    padding-bottom:calc(16rem/16);
    @media (max-width: 767px) {
        padding-top:calc(10rem/16);
        padding-bottom:calc(10rem/16);
    }
}
.side-nav__top-col + .side-nav__top-col {
    border-left:.25rem solid var(--color-light-grey);
}
.side-nav__top-title {
    font-family: var(--font-default-bold);
    font-size:calc(24rem/16);
    margin-left:4rem;
    @media (max-width: 767px) {
        font-size:calc(16rem/16);
        margin-left:2rem;
    }
}
.side-nav__top-back,
.side-nav__top-close {
    position: relative;
    font-family: var(--font-default-bold);
    color: inherit;
    text-decoration: none;
    display: block;
    font-size:calc(20rem/16);
    padding:0 0 0 calc(34rem/16);
    margin-right:2rem;
    margin-left:0;
    margin-top:.25rem;
    &:hover,&:focus {
        color: var(--color-primary);
    }
    @media (max-width: 767px) {
        font-size:calc(14rem/16);
        padding:0 0 0 calc(14rem/16);
        margin-right:1.25rem;
        margin-top:.125rem;
    }
}
.side-nav__top-back:before,
.side-nav__top-close:before {
    font-family: iconfont;
    content: var(--icon-arrow-left);
    color: var(--color-primary);
    font-size: 2rem;
    line-height:1px;
    position: absolute;
    left:.5rem;
    top:50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
        left:-.375rem;
        font-size: 1.375rem;
    }
}
.side-nav__top-close:before {
    content: var(--icon-close);
    font-size: 1.25rem;
    @media (max-width: 767px) {
        font-size: 1rem;
    }
}
.side-nav__top-close--search {
    position: absolute;
    right: 2.5rem;
    top: 2rem;
    color: #fff;
    margin: 0 !important;
    @media (max-width:767px) {
        right:1.5rem;
        top:1rem;
    }
}

.side-nav__section {
    padding:2.5rem 4rem;
    @media (max-width: 767px) {
        padding:1.5rem 2rem;
    }
}
.side-nav__section--teaser {
    padding-top:0;
}
.side-nav__section + .side-nav__section:not(.side-nav__section--teaser) {
    border-top:.25rem solid var(--color-light-grey);
}


/* default style */
.side-nav__list-item {
    font-size:1.75rem;
    @media (max-width: 767px) {
        font-size:calc(22rem/16);
    }
}
.side-nav__list-item + .side-nav__list-item {
    margin-top:calc(34rem/16);
    @media (max-width: 767px) {
        margin-top:calc(18rem/16);
    }
}
.side-nav__list-link {
    position: relative;
    font-family: var(--font-default-bold);
    color: inherit;
    text-decoration: none;
    display: inline-block;
    font-size:inherit;
    &:hover,&:focus {
        color: var(--color-primary);
    }
    &.active {
        color: var(--color-primary);
    }
}
.side-nav__list-link--sub,
.side-nav__list-link--direct {
    padding-left:calc(20rem/16);
    @media (max-width: 767px) {
        padding-left:calc(17rem/16);
    }
}
.side-nav__list-link--sub::before {
    font-family: iconfont;
    content: var(--icon-arrow-right);
    color: var(--color-primary);
    font-size: inherit;
    position: absolute;
    left:0;
    top:0;
}


/* medium style */
.side-nav__list-item--medium  {
    font-size:calc(25rem/16);
    @media (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}
.side-nav__list-item + .side-nav__list-item--medium {
    margin-top:calc(20rem/16);
    @media (max-width: 767px) {
        margin-top:calc(10rem/16);
    }
}


/* small style */
.side-nav__list-item--small {
    font-size:calc(20rem/16);
    @media (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}
.side-nav__list-item + .side-nav__list-item--small {
    margin-top:calc(12rem/16);
    @media (max-width: 767px) {
        margin-top:calc(8rem/16);
    }
}