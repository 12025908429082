.narrow-form {
    max-width: calc(600rem/16);
    margin-left:auto;
    margin-right:auto;
}
.transparent-placeholder::placeholder {
    color: transparent !important;
}
.form-group,
.form-check{
    position: relative;
    margin-bottom: calc(28rem/16);

    @media (min-width: 768px) {
        margin-bottom: 2rem;
    }
}
.form-group--file-upload{
    margin-bottom: 0;
    @media (min-width: 768px) {
        margin-bottom: 1.875rem;
    }
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-text-placeholder);
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-text-placeholder);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-text-placeholder);
}

.form-control--icon {
    position: absolute;
    right:1rem;
    top:3.25rem;
    color:var(--color-primary);
}

/* LABEL */
.form-label{
    font-family: var(--font-default-bold);
    font-size:.9375rem;
    @media (min-width: 1400px) {
        font-size:1rem;
    }
}

/* SELECT */
.form-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23d51e27' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M2 5l6 6 6-6'/%3E%3C/svg%3E")
}

/* TEXTAREA */
textarea.form-control {
    line-height: calc(26/18);
}
.offer-tool-form textarea.form-control{
    min-height: calc(164rem/16);
}

/* CHECKBOX */
.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 20 20' %3E%3Cpath fill= 'none' stroke= '%23fff' stroke-linecap= 'round' stroke-linejoin= 'round' stroke-width= '2' d= 'M6 10l3 3 6-6' /%3E%3C/svg%3E");
}
.form-group .form-check{
    margin-bottom: 1rem;
}
.form-check.form-check__row{
    padding-top: calc(11rem/16);
    padding-bottom: calc(11rem/16);
}
.form-check-input{
    margin-top: 0;
}
.form-check-label{
    white-space: normal;
}
.form-check-label a{
    color: var(--color-primary);
    text-decoration: underline;
}
.form-check-label a:hover,
.form-check-label a:focus{
    text-decoration: none;
}
.form-check-label--bold{
    font-family: var(--font-default-bold);
}

/* CHECKBOX GROUP */
.form-group .form-check{
    display: block;

    @media (min-width: 768px) {
        display: inline-block;
        margin-right: calc(32rem/16);
    }
}
.form-group .form-label{
    font-size: inherit;
}

.input-group-text {
    padding: calc(17rem/16) calc(18rem/16);
    font-size:calc(15rem/16);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* RADIO */
.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23d51e27'/%3E%3C/svg%3E");
    background-color: #fff;
    border-color: rgba(41,49,51,0.2);
}

/* FILE UPLOAD */
input[type="file"]::file-selector-button {
    content: "";
    display: none;
    visibility: hidden;
}
input[type='file'].file-upload__empty-input {
    color: rgba(0, 0, 0, 0)
}
.file-upload__empty-input::before {
    content: attr(data-info);
    color: var(--color-text-default)
}
.file-upload__label {
    border: 1px solid var(--color-primary);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    padding: calc(10rem/16) calc(16rem/16);
    cursor: pointer;
    background-color: #fff;
    font-family: var(--font-default-bold);
}
.file-upload__label:hover,
.file-upload__label:hover .icon{
    color: #fff;
    background-color: var(--color-primary);
}
.file-upload-group:has(> .form-control:focus),
.file-upload-group:has(> .file-upload__label:active){
    box-shadow: 0 0 0 0.1rem rgba(41, 49, 51, .2);
    border-radius:var(--border-radius);
}
.delete-file__btn{
    padding: calc(5rem/16);
    font-family: var(--font-default);
}
.delete-file__item:not(:last-child){
    margin-right: calc(40rem/16);
}
.delete-file__list{
    line-height:1.5;
    margin-bottom: calc(30rem/16);
    padding-left:0;
    margin-left:0;
    list-style-type: none;

    @media (max-width: 767px) {
        margin-bottom: calc(14rem/16);
    }
}
.delete-file__list > li{
    margin-bottom:.375rem;
    margin-left:0;
    padding-left:1.25rem;
    list-style: none;
    position: relative;
}
.delete-file__list > li:before {
    content:'';
    position: absolute;
    left:0;
    top:.625rem;
    display: block;
    width:calc(6rem/16);
    height:calc(6rem/16);
    background: var(--color-primary);
    border-radius: 100%;
}

/* DATE INPUT */
.date-input:focus{
    box-shadow: 0 0 0 0.1rem rgba(41,49,51,0.2);
}
.date-input .form-control{
    border-right: none;
}
.date-input .form-control:focus{
    border-color: rgba(41, 49, 51, .2);
    box-shadow: none;
}
.btn-date{
    border: 1px solid rgba(41, 49, 51, .2);
    border-left: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;

    padding: calc(11rem/16) calc(20rem/16);
    background-color: #fff;
}
.btn-date:hover,
.btn-date:focus,
.btn-date:active{
    border-style: solid solid solid none;
    border-color: rgba(41, 49, 51, .2) !important;
    border-width: 1px;

    background-color: #fff!important;
}
.date-input:has(> .form-control:focus),
.date-input:has(> .btn-date:active){
    box-shadow: 0 0 0 0.1rem rgba(41, 49, 51, .2);
    border-radius: var(--border-radius);
}

/* FORM CLUSTER */
.form-cluster{
    background-color: #fff;
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);

    padding: calc(32rem/16);
    width: 100%;
}
.form-cluster .form-group{
    margin-bottom: 0
}
.form-cluster .wysiwyg{
    white-space: normal;
}

/* VALIDATION */
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus{
    box-shadow: 0 0 0 .25rem rgba(41,49,51,0.2);
}

.form-check-input.is-valid~.form-check-label{
    color: var(--color-text-default);
}
.form-check-input.is-valid:checked{
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: 0 0 0 .25rem rgba(var(--color-primary), .25);
}