.accordion {
    position: relative;
    background-color: #fff;
    border-radius:var(--border-radius);
    border:0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.accordion-item {
    border:0;
    box-shadow: none;
    border-bottom: 1px solid #e7e7e7;
}
.accordion-item:last-of-type {
    border-bottom:0;
}
.accordion-item--table .list-inline-item {
    margin: 0;

    @media (max-width: 767px){
        &:last-of-type{
            padding-left: calc(24rem/16);
        }
    }

    @media (min-width: 768px) {
        padding: 0 calc(30rem/16);
        border-left: 3px solid var(--color-light-grey);

        &:last-of-type{
            padding-right:0;
        }
        &:first-of-type{
            padding-left:0;
            border-left: none;
        }
    }
}
.accordion-button {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding:1rem 1.5rem;
    text-align:left;
    font-size:calc(18rem / 16);
    color:inherit;
    font-family: var(--font-default-bold);
    font-weight: normal;
    box-shadow: none !important;

    @media (min-width: 768px){
        padding:1.375rem 2rem;
        font-size:calc(18rem / 16);
    }
}
.accordion-button--table{
    padding: 0;

    @media (min-width: 768px){
        padding: 0;
        font-size: calc(18rem/16);
    }
}
.accordion-button:hover,
.accordion-button:focus{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.accordion-button:after{
    display: none;
}
.accordion-button > .icon {
    color:inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.125rem;
}
.accordion-button:not(.collapsed) {
    background:#fff;
    color:inherit;
}
.accordion-toggle-all.open .icon:before,
.accordion-button:not(.collapsed) > .icon:before {
    position: relative;
    transform: rotate(45deg);
    display: block;
}
.accordion-body {
    padding:.5rem 1.5rem 1.5rem 1.5rem;

    @media (min-width: 768px){
        padding:.5rem 2rem 2rem 2rem;
    }
}