:root {
    --color-primary: #D51E27;
    --color-primary-dark: #b51a21;
    --color-secondary: #293133;
    --color-text-default: #293133;
    --color-text-muted: #d4d6d6;
    --color-text-placeholder: #7f8385;

    --color-default: #293133;
    --color-white: #FFF;
    --color-light-grey: #F4F4F5;
    --color-mid-grey: #d4d6d6;
    --color-dark-grey: #a9adad;
    --color-dark: #293133;

    --color-success: #28A745;
    --color-info: #E07306;
    --color-warning: #E07306;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-active: #23A339;
    --color-inactive: var(--color-primary);

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "TradeGothicNextRegular", sans-serif;
    --font-default-bold: "TradeGothicNextBold", sans-serif;

    --font-size-default: 1rem;

    --header-height: calc(100rem / 16);
    --header-height-xs: calc(80rem / 16);

    --sidenav-top-height: calc(72rem / 16);
    --sidenav-top-height-xs: calc(44rem / 16);

    --border-radius: calc(8rem / 16);
}
