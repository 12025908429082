.main-nav {
    position: relative;
    margin:0;
    @media (max-width: 1500px) {
        display: none;
    }
}
.main-nav__item {
    position: relative;
    display: inline-flex;
    @media (min-width: 768px) {
        margin:0 0 0 calc((40rem / 16) - 1rem);
    }
    @media (min-width: 1600px) {
        margin:0 0 0 calc((60rem / 16) - 1rem);
    }
}

.main-nav__link {
    font-size:1.25rem;
    font-family: var(--font-default-bold);
    display: inline-flex;
    height:var(--header-height);
    padding-top:2.75rem;
    color: #fff;
    text-decoration: none;
    transition: opacity .3s;
    border-bottom:.25rem solid transparent;
}
.active .main-nav__link {
    border-bottom-color: #fff;
}
.main-nav:hover .main-nav__link {
    opacity: .55;

}
.main-nav:hover .main-nav__link:hover,
.main-nav:hover .main-nav__link:focus {
    color: #fff;
    opacity: 1;
}