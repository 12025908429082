.hotspot-container{
    position: relative;
}
.hotspot-container.is-open{
    z-index: 110;
}
.hotspot {
    position: absolute;
    width: calc(56rem/16);
    height: calc(56rem/16);
    display: block;
    z-index: 2;
}
.hotspot.is-open {
    z-index: 113;
}
.hotspot__toggle:focus {
    outline: none;
}
.hotspot__toggle{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
}
.hotspot__toggle:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: calc(2rem/16) solid #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
}
.hotspot__toggle:before {
    content: '';
    height: calc(36rem/16);
    width: calc(36rem/16);
    background:#fff;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
    transition: background 250ms ease-in-out;
    opacity: 1;
}
.is-open .hotspot__toggle:before {
    background:var(--color-primary);
    z-index: 2;
}
.hotspot__toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 250ms ease-in-out;
    z-index: 2;
    font-size: 1rem;
    color: var(--color-primary);
}
.is-open .hotspot__toggle__icon {
    color:#fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

/* hotspot detail */
.hotspot__detail{
    position: fixed;
    left: 0;
    right: 0;
    margin: 5% auto;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    cursor: default;
    transition: opacity .2s, visibility 0s;
    max-width: calc(90vw - 40px);
    overflow:hidden;
    border-radius:var(--border-radius);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);

    @media (min-width: 768px) {
        position: absolute;
        bottom: calc(70rem/16);
        left: 50%;
        right:.875rem;
        transform: translateX(-50%);
        width: 22rem;
        margin: 0;
    }

}
.is-open .hotspot__detail {
    opacity: 1;
    visibility: visible;
}

.hotspot__detail-body {
    padding: calc(15rem/16) calc(20rem/16) calc(30rem/16);
}
.hotspot__detail-img {
    border-bottom: 1px solid var(--color-light-grey);
    padding: calc(30rem/16);
    box-shadow: 0px 5px 10px 0px var(--color-mid-grey);
}
.hotspot__detail-close{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: calc(17rem/16) calc(20rem/16);
}
.hotspot__detail-close:focus{
    color: white;
    border: none;
}
.hotspot__detail-title{
    font-size: calc(20rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
}

@media (min-width: 768px) {
    .hotspot--detail-right .hotspot__detail {
        transform: translateX(0);
    }
    .hotspot--detail-left .hotspot__detail {
        transform: translateX(-1.75rem);
    }
}


.circle-animation {
    width: calc(56rem/16);
    height: calc(56rem/16);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.circle-animation__circle {
    stroke-dasharray: 0 100;
}
.hotspot:hover .circle-animation {
    animation: circle-rotate 1200ms forwards infinite linear;
}
.hotspot.is-open:hover .circle-animation {
    animation-play-state: paused;
}
@keyframes circle-rotate {
    from {transform: rotate(-90deg)}
    to {transform: rotate(270deg)}
}
.hotspot:hover .circle-animation__circle,
.hotspot__toggle:focus .circle-animation__circle {
    stroke: var(--color-primary);
    animation: circle-fill-hover 1s infinite linear;
    animation-direction: alternate;
}
.hotspot.is-open .circle-animation__circle,
.hotspot.is-open:hover .circle-animation__circle {
    stroke:  var(--color-primary);
    animation: circle-fill 500ms forwards linear;
}
@keyframes circle-fill-hover {
    to { stroke-dasharray: 30 100; }
}

@keyframes circle-fill {
    to { stroke-dasharray: 100 100; }
}