.service-popup__btn{
    position: relative;
    z-index: 920;
}
.service-popup__btn.is-open .icon-close,
.service-popup__btn .icon-question{
    display: inline-block;
}
.service-popup__btn .icon-close,
.service-popup__btn.is-open .icon-question{
    display: none;
}


.service-popup__btn-sticky{
    position: fixed;
    bottom: 30px;
    right: 1rem;

    @media (max-width: 767px) {
        bottom: .875rem;
    }
}
.service-popup__btn-sticky--pdp{
    bottom: 8.875rem
}
.service-popup__btn-sticky--visible{
    opacity: 1;
    transform: translateY(0%);
    transition: all 1s;
}
.service-popup__btn-sticky--hidden{
    opacity: 0;
    transform: translateY(-240%);
    transition: all 0.5s;
    z-index:0;
    pointer-events: none;
}

.popover.service-popover .popover-header {
    border-bottom: 2px solid var(--color-light-grey);
}

.popover:has(.service-popup) .is-open,
.popover:has(.customer-service-popup) .is-open,
.popover:has(.requests-service-popup) .is-open{
    overflow: visible;
}

.popover:has(.service-popup),
.popover:has(.customer-service-popup),
.popover:has(.requests-service-popup){
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    min-height: calc(585rem/16);
    width: calc(450rem/16);
    overflow: hidden;

    @media (max-width: 767px) {
        width: calc(100% - 5rem);
        margin-left:-1rem !important;
        min-height: auto;
    }
}
.popover-body:has(.customer-service-popup),
.popover-body:has(.requests-service-popup){
    padding: calc(30rem/16) calc(47rem/16) calc(47rem/16) calc(47rem/16);
}
.service-popup__btn-close{
    padding: calc(18rem/16);
}
.service-popup__btn-info{
    font-family: var(--font-default);
}
.service-popup__icon{
    font-size: calc(43rem/16);
    width: calc(43rem/16);
    height: calc(43rem/16);
}