.sticky-info-area {
    position: fixed;
    left:1rem;
    bottom:calc(30rem/16);
    z-index: 1080;
    width:auto;
    max-width:25rem;
    transition: all 0.5s;

    @media (max-width: 767px) {
        max-width:13rem;
        bottom: .875rem;
    }
}
.sticky-info-area__block {
    display: inline-block;
    color:#fff;
    padding:.5rem 1rem;
    font-size:calc(14rem/16);
    border-radius:var(--border-radius);
}
.sticky-info-area__block + .sticky-info-area__block {
    margin-top:.5rem;
}
.sticky-info-area__block a {
    font-family:var(--font-default-bold);
    text-decoration: underline;
    color:inherit;
}
.sticky-info-area__block--warning {
    background:var(--color-warning);
}
.sticky-info-area__block--success {
    background:var(--color-success);
}
.sticky-info-area__block--danger {
    background:var(--color-danger);
}
.sticky-info-area--pdp{
    bottom: 8.875rem;
    transition: all 0.5s;
}