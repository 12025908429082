.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table:not(.cockpit-table) {
    line-height:1.5;
    margin-bottom: calc(30rem/16);

    @media (max-width: 767px) {
        margin-bottom: calc(14rem/16);
    }
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:not(.cockpit-table):last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn, .page-link){
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:hover:not(.btn, .page-link),
.wysiwyg a:focus:not(.btn, .page-link),
.wysiwyg a:active:not(.btn, .page-link) {
    color: var(--color-primary);
    text-decoration: none;
}
.wysiwyg a.document__link{
    color: var(--color-text-default);
}
.wysiwyg a.document__link:hover,
.wysiwyg a.document__link:focus,
.wysiwyg a.document__link:active {
    color: var(--color-text-default);
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin:0 0 .625rem 0;
}


.wysiwyg ol,
.wysiwyg ul {
    padding-left:0;
    margin-left:0;
    list-style-type: none;
}
.wysiwyg ol>li:not(.page-item),
.wysiwyg ul>li:not(.page-item) {
    margin-bottom:.375rem;
    margin-left:0;
    padding-left:1.25rem;
    list-style: none;
    position: relative;
}
.wysiwyg ul>li:not(.page-item):before {
    content:'';
    position: absolute;
    left:0;
    top:.625rem;
    display: block;
    width:calc(6rem/16);
    height:calc(6rem/16);
    background: var(--color-primary);
    border-radius: 100%;
}

.wysiwyg ol{
    counter-reset: counter;
}
.wysiwyg ol>li:not(.page-item){
    counter-increment: counter;
}
.wysiwyg ol>li:not(.page-item):before {
    display: block;
    color:var(--color-primary);
    position: absolute;
    left:0;
    top:0;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    content: counter(counter) ". ";
}