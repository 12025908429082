.grid-teaser {
    position: relative;
    border-radius:var(--border-radius);
    overflow: hidden;
    height: 100%;
    min-height:calc(360rem/16);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.grid-teaser:before {
    content:'';
    position:absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    background:#000;
    opacity: .2;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
}
.grid-teaser:hover:before {
    opacity: 0;
}
.grid-teaser__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display:block;
    position:absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
}
.grid-teaser__inner {
    max-width:75%;
    margin-left:auto;
    margin-right:auto;
}
.grid-teaser__title {
    margin-bottom: calc(10rem/16);
    @media (min-width:1200px) {
        font-size:calc(34rem / 16);
    }
}
.grid-teaser__title--dark {
    color:var(--color-text-default);
}
.grid-teaser__body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding:calc(30rem/16);
    color:#fff;
    display: flex;
    flex-direction: column;
    z-index: 2;
    @media (min-width:768px) {
        padding:calc(40rem/16);
    }
}
.grid-teaser__body--top {
    justify-content: start;
}
.grid-teaser__body--middle {
    justify-content: center;
}
.grid-teaser__body--bottom {
    justify-content: end;
}

.grid-teaser__body--left {
    text-align: left;
}
.grid-teaser__body--center {
    text-align: center;
}
.grid-teaser__body--right {
    text-align: right;
}
