.search-overlay {
    position: fixed;
    left:0;
    right:0;
    transform:translateY(-30rem);
    transition: all .3s;
    top:var(--header-height);
    background:#fff;
    z-index:1040;
    padding:calc(35rem / 16) calc(20rem / 16);
    border-top:.125rem solid #7F8385;
    @media (max-width:767px) {
        top:var(--header-height-xs);
        padding:calc(25rem / 16) calc(16rem / 16);
    }
}
.search-overlay--pdp{
    top: calc(54px + var(--header-height));
}
.search-overlay.open {
    transform: translateY(0);
    opacity: 1;
}

.search-overlay__btn {
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    height:auto;
    line-height:1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.search-overlay__btn > .icon.icon {
    vertical-align: 0;
}