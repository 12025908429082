.blaze-slider {
    position: relative;
}

.blaze-track-container {
    position: relative;
}

.arrow-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left:0;
    right:0;
    width: auto;
    transform: translateY(-50%);
    pointer-events: none;

    @media (min-width: 1680px) {
        left:calc(-46rem/16);
        right:calc(-46rem/16);
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.arrow-container .blaze-prev,
.arrow-container .blaze-next {
    font-size: 3.5rem;
    line-height: calc(10rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 0;
    color: var(--color-primary);
    pointer-events: initial;
    background-color: transparent;
}
.arrow-container .blaze-prev:hover,
.arrow-container .blaze-next:hover {
    color: var(--color-text-default);
}
.arrow-container .blaze-prev:focus,
.arrow-container .blaze-next:focus {
    color: var(--color-primary);
}

.blaze-pagination {
    display:block;
    width: 100%;
    text-align: center;
    margin-top: calc(20rem/16);

    @media (min-width: 768px) {
        display: none;
    }
}


.blaze-pagination > button {
    -webkit-appearance: none;
    width:.875rem;
    height:.875rem;
    text-indent: -199999px;
    display: inline-block;
    margin:0 .3125rem;
    border-radius: 100%;
    background: #000;
    opacity: .95;
}

.blaze-pagination > button.active {
    opacity: .4;
}


//TEASER SLIDER
.teaser-slider {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 75%;
        height: 80%;
        background-color: var(--color-secondary);
        z-index: -1;
    }
}

.teaser-slider .blaze-track {
    width: 90%;
    margin-left: 0;
    padding-top: calc(10rem/16);
    padding-bottom: calc(40rem/16);

    @media (min-width: 992px) {
        padding-bottom: calc(100rem/16);
    }

    @media (min-width: 1200px) {
        width: 100%;
        margin-left: auto;
    }
}

.teaser-slider .arrow-container {
    @media (min-width: 1200px) {
        top: calc(50% - 50px);
    }
}

.teaser-slider .arrow-container .blaze-prev {
    margin-left: calc(30rem/16);
}

.teaser-slider .arrow-container .blaze-next {
    margin-right: calc(30rem/16);
}

//IMAGE SLIDER
.image-slider {
    position: relative;
    padding-bottom: calc(90rem/16);

    &:before {
        content: '';
        position: absolute;
        width: calc(217rem/16);
        height: calc(110rem/16);
        bottom: 0;
        right: calc(-20rem/16);
        background-color: var(--color-secondary);
        z-index: -1;
    }

    @media (min-width: 768px) {
        &:before {
            width: calc(400rem/16);
            height: calc(200rem/16);
            right: calc(-80rem/16);
        }
    }

    @media (min-width: 1200px) {
        &:before {
            width: calc(600rem/16);
            height: calc(270rem/16);
            right: calc(-130rem/16);
        }
    }
}

.image-slider .arrow-container {
    display: block;
    position: absolute;
    top: auto;
    left: auto;
    right: calc(-10rem / 16);
    bottom: calc(-27rem / 16);
    width: auto;
    transform: none;

    @media (min-width: 1200px) {
        right: calc(30rem / 16);
        bottom: calc(-40rem / 16);
    }
}

.image-slider .arrow-container .blaze-prev,
.image-slider .arrow-container .blaze-next {
    width: calc(50rem/16);
    height: calc(50rem/16);

    @media (min-width: 1200px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
    }
}

//IMAGE TEASER SLIDER
.image-teaser-slider .blaze-track {
    margin-left: 0;
    width: 60%;

    @media (min-width: 992px) {
        width: 80%;
    }

    @media (min-width: 1200px) {
        width: calc(100% - 40px);
    }
}

//TEXT TEASER SLIDER
.blaze-slider--teaser{
    margin: calc(-20rem/16) calc(-20rem/16) 0 calc(-20rem/16);

    @media (max-width: 767px) {
        margin-right:calc(-36rem/16);
        margin-left:calc(-36rem/16);
    }
}

.blaze-slider--teaser .blaze-track-container {
    @media (max-width: 767px) {
        padding-left:1.5rem;
        margin-left:-.5rem;
    }
}

.teaser-container--slider{
    padding: calc(20rem/16) calc(10rem/16) calc(20rem/16) calc(20rem/16);

    @media (min-width: 768px) {
        padding: calc(20rem/16);
        max-width: 33.3%;
    }
    @media (min-width: 1200px) {
        max-width: 25%;
    }
}

/*CENTERED SLIDER*/
.blaze-slider--teaser .blaze-track.blaze-track--center,
.blaze-slider--benefits .blaze-track.blaze-track--center{
    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
    }
}

/*GRID DISPLAY*/
.blaze-slider--teaser.static .blaze-track{
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.blaze-slider--teaser.static .teaser-container--slider{
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 calc(20rem/16) calc(20rem/16);
    }
}
.blaze-slider--teaser.static .teaser-container--slider:first-child{
    @media (max-width: 768px) {
        padding: calc(20rem/16);
    }
}

/*PAGINATION AND ARROWS*/
.blaze-slider--teaser .blaze-pagination{
    @media (max-width: 768px){
        margin-top: calc(12rem/16)
    }
}
.blaze-slider--teaser .arrow-container,
.blaze-slider--highlight .arrow-container{
    @media (min-width: 768px) and (max-width: 1679px) {
        display: block;
        position: absolute;
        top: auto;
        left: auto;
        right: calc(30rem / 16);
        bottom: calc(-60rem / 16);
        width: auto;
        transform: none;
    }
}
.blaze-slider--teaser .arrow-container .blaze-prev,
.blaze-slider--teaser .arrow-container .blaze-next,
.blaze-slider--highlight .arrow-container .blaze-prev,
.blaze-slider--highlight .arrow-container .blaze-next{
    width: calc(50rem/16);
    height: calc(50rem/16);

    @media (min-width: 1200px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
    }
}

/* pdp-slider */
.blaze-slider--pdp .arrow-container{
    @media (min-width: 992px) {
        left: 0;
        right: 0;
    }
}
.blaze-slider--pdp .blaze-container{
    @media (min-width: 992px) {
        margin: 0 calc(120rem/16);
    }
}

/* pdp-accessories-slider */
.pdp-container--slider{
    @media (min-width: 768px) {
        max-width: 50%;
        padding: 1.25rem;
    }

}
