.alert {
    text-align: center;
    font-family: var(--font-default-bold);
    font-weight: normal;
}

/*! purgecss start ignore */
.alert-notification--fixed {
    position: fixed;
    bottom: 90px;
    right: 0;
    max-height: 85vh;
    pointer-events: none;
    z-index: 1056;
    padding: calc(18rem/16);

    @media screen and (min-width: 768px){
        bottom: 60px;
    }
}
.alert-notification__container {
    pointer-events: auto;
    width: 100%;
    max-width: calc(686rem / 16);
    overflow: visible;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
}
.alert-notification__container .alert{
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    border-radius: var(--border-radius);
    border: none;
}
.alert .close {
    display: block;
    position: absolute;
    top:-.5rem;
    left:-.5rem;
    width:1rem;
    height:1rem;
    line-height:.875rem;
    text-align: center;
    font-size:1.125rem;
    border-radius: 100%;
    background:#fff;
    box-shadow: 0 0 calc(5rem / 16) 0 rgba(62,61,64,0.5);
}
.alert .close>span {
    position: relative;
    top:-.125rem;
}
.alert-dismissible {
    padding-right: var(--bs-alert-padding-x);
}
.alert-list__container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.alert-list{
    width: fit-content;
    text-align: left;
}
/*! purgecss end ignore */