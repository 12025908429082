.quickfilter__btn {
    display:inline-block;
    padding:.5rem .625rem;
    line-height:1;
    font-size:.75rem;
    border:1px solid var(--color-mid-grey);
    -webkit-appearance: none;
    margin:.25rem .25rem .25rem 0;
    background: transparent;
    border-radius:.375rem;
    font-family: var(--font-default-bold);
    @media (min-width: 768px) {
        padding:.625rem 1rem;
        font-size:.875rem;
    }
    &:hover,
    &:focus{
        background: #fff;
        border:1px solid var(--color-mid-grey);
    }
}
.quickfilter__btn .icon {
    vertical-align: -.1em;
    margin-left:.25rem;
    color:var(--color-primary);
}