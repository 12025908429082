video[poster]{
    object-fit: cover;
}

.video__overlay {
    position:absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    background: rgba(0,0,0,.4);
}

.video__iframe {
    background-position:50% 50%;
    background-size:cover;
    background-repeat: no-repeat;
}

.video__play {
    position: absolute;
    left:50%;
    top:50%;
    right:auto;
    bottom:auto;
    width:calc(90rem / 16);
    height:calc(90rem / 16);
    background:rgba(0,0,0,.1);
    border-radius: 100%;
    border:calc(3rem / 16) solid var(--color-primary);
    color:#fff;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
    @media (min-width: 768px) {
        width:calc(124rem / 16);
        height:calc(124rem / 16);
    }
    &:hover {
        background-color: var(--color-primary);
    }
}

.video__play:before {
    display:block;
    content: '';
    position: absolute;
    top: 50%;
    left: 53%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #fff;
    transform: translate(-50%, -50%);
    @media (min-width: 768px) {
        border-width: 27px 0 27px 27px;
    }
}

/* small button */
.video__play--small {
    width:calc(50rem / 16);
    height:calc(50rem / 16);
    @media (min-width: 768px) {
        width:calc(80rem / 16);
        height:calc(80rem / 16);
    }
}
.video__play--small:before {
    border-width: 14px 0 14px 14px;
    @media (min-width: 768px) {
        border-width: 20px 0 20px 20px;
    }
}