/* checkout step links */
.checkout-step__item{
    position: relative;
}
.checkout__step{
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(0, -50%);
    width: .625rem;
    height: .625rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid rgba(41,49,51,0.2)
}
.checkout-step__item--active{
    color: var(--color-primary);
}
.checkout-step__item--active .checkout__step {
    background-color: var(--color-primary);
}
.checkout-step__next{
    position: sticky;
    top: 150px;
    width: 100%
}

/* checkout info */
.checkout-info{
    color: #7F8385;
}

.checkout-anchor{
    scroll-margin-top: calc(140rem/16);
}