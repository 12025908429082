@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-double-back:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-right:before { content: "\EA03" }
.icon-big-arrow-down:before { content: "\EA04" }
.icon-big-arrow-right:before { content: "\EA05" }
.icon-bin:before { content: "\EA06" }
.icon-blog:before { content: "\EA07" }
.icon-calendar:before { content: "\EA08" }
.icon-cart:before { content: "\EA09" }
.icon-check-badge:before { content: "\EA0A" }
.icon-check:before { content: "\EA0B" }
.icon-checklist:before { content: "\EA0C" }
.icon-close:before { content: "\EA0D" }
.icon-cockpit:before { content: "\EA0E" }
.icon-cooling:before { content: "\EA0F" }
.icon-disposition:before { content: "\EA10" }
.icon-document-with-lens:before { content: "\EA11" }
.icon-document:before { content: "\EA12" }
.icon-download:before { content: "\EA13" }
.icon-dropdown:before { content: "\EA14" }
.icon-elements:before { content: "\EA15" }
.icon-evidence:before { content: "\EA16" }
.icon-facebook:before { content: "\EA17" }
.icon-fuehrungskraft:before { content: "\EA18" }
.icon-grid:before { content: "\EA19" }
.icon-heating:before { content: "\EA1A" }
.icon-house-outside:before { content: "\EA1B" }
.icon-info:before { content: "\EA1C" }
.icon-inquiry:before { content: "\EA1D" }
.icon-it-software:before { content: "\EA1E" }
.icon-kundendienst:before { content: "\EA1F" }
.icon-lagerist:before { content: "\EA20" }
.icon-link:before { content: "\EA21" }
.icon-linkedin:before { content: "\EA22" }
.icon-mail:before { content: "\EA23" }
.icon-menue:before { content: "\EA24" }
.icon-montage-aussendienst:before { content: "\EA25" }
.icon-montage-produktion:before { content: "\EA26" }
.icon-office:before { content: "\EA27" }
.icon-open:before { content: "\EA28" }
.icon-operating-place:before { content: "\EA29" }
.icon-pen:before { content: "\EA2A" }
.icon-phone:before { content: "\EA2B" }
.icon-pinterest:before { content: "\EA2C" }
.icon-plus-circle:before { content: "\EA2D" }
.icon-plus:before { content: "\EA2E" }
.icon-poi:before { content: "\EA2F" }
.icon-produktmanagement:before { content: "\EA30" }
.icon-profile:before { content: "\EA31" }
.icon-question:before { content: "\EA32" }
.icon-scroll:before { content: "\EA33" }
.icon-search:before { content: "\EA34" }
.icon-silent:before { content: "\EA35" }
.icon-star-filled:before { content: "\EA36" }
.icon-star-outline:before { content: "\EA37" }
.icon-switch:before { content: "\EA38" }
.icon-teilzeit:before { content: "\EA39" }
.icon-time:before { content: "\EA3A" }
.icon-tools:before { content: "\EA3B" }
.icon-touch:before { content: "\EA3C" }
.icon-trainee:before { content: "\EA3D" }
.icon-upload:before { content: "\EA3E" }
.icon-vertrieb-aussendienst:before { content: "\EA3F" }
.icon-vollzeit:before { content: "\EA40" }
.icon-whatsapp:before { content: "\EA41" }
.icon-work-location:before { content: "\EA42" }
.icon-x:before { content: "\EA43" }


:root {
--icon-arrow-double-back: "\EA01";
    --icon-arrow-left: "\EA02";
    --icon-arrow-right: "\EA03";
    --icon-big-arrow-down: "\EA04";
    --icon-big-arrow-right: "\EA05";
    --icon-bin: "\EA06";
    --icon-blog: "\EA07";
    --icon-calendar: "\EA08";
    --icon-cart: "\EA09";
    --icon-check-badge: "\EA0A";
    --icon-check: "\EA0B";
    --icon-checklist: "\EA0C";
    --icon-close: "\EA0D";
    --icon-cockpit: "\EA0E";
    --icon-cooling: "\EA0F";
    --icon-disposition: "\EA10";
    --icon-document-with-lens: "\EA11";
    --icon-document: "\EA12";
    --icon-download: "\EA13";
    --icon-dropdown: "\EA14";
    --icon-elements: "\EA15";
    --icon-evidence: "\EA16";
    --icon-facebook: "\EA17";
    --icon-fuehrungskraft: "\EA18";
    --icon-grid: "\EA19";
    --icon-heating: "\EA1A";
    --icon-house-outside: "\EA1B";
    --icon-info: "\EA1C";
    --icon-inquiry: "\EA1D";
    --icon-it-software: "\EA1E";
    --icon-kundendienst: "\EA1F";
    --icon-lagerist: "\EA20";
    --icon-link: "\EA21";
    --icon-linkedin: "\EA22";
    --icon-mail: "\EA23";
    --icon-menue: "\EA24";
    --icon-montage-aussendienst: "\EA25";
    --icon-montage-produktion: "\EA26";
    --icon-office: "\EA27";
    --icon-open: "\EA28";
    --icon-operating-place: "\EA29";
    --icon-pen: "\EA2A";
    --icon-phone: "\EA2B";
    --icon-pinterest: "\EA2C";
    --icon-plus-circle: "\EA2D";
    --icon-plus: "\EA2E";
    --icon-poi: "\EA2F";
    --icon-produktmanagement: "\EA30";
    --icon-profile: "\EA31";
    --icon-question: "\EA32";
    --icon-scroll: "\EA33";
    --icon-search: "\EA34";
    --icon-silent: "\EA35";
    --icon-star-filled: "\EA36";
    --icon-star-outline: "\EA37";
    --icon-switch: "\EA38";
    --icon-teilzeit: "\EA39";
    --icon-time: "\EA3A";
    --icon-tools: "\EA3B";
    --icon-touch: "\EA3C";
    --icon-trainee: "\EA3D";
    --icon-upload: "\EA3E";
    --icon-vertrieb-aussendienst: "\EA3F";
    --icon-vollzeit: "\EA40";
    --icon-whatsapp: "\EA41";
    --icon-work-location: "\EA42";
    --icon-x: "\EA43";
    
}