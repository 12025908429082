.subitem-title{
    padding: calc(16rem/16);
    background-color: var(--color-light-grey)
}
.subitem-content{
    margin: calc(16rem/16) 0;

    @media (min-width: 768px) {
        padding: 0 calc(16rem/16);
    }
}
.subitem-content-title{
    @media (min-width: 768px) {
        width: 40%
    }
}
.subitem-content-price,
.subitem-content-amount{
    @media (min-width: 768px) {
        min-width: 10%
    }
}