.badge {
    display: inline-block;
    border-radius:.375rem;
    text-align:center;
    font-size:.75em;
    color:#fff;
    padding:.25rem .75rem .375rem .75rem;
    line-height:1;
    font-family:var(--font-default-bold);
}
.badge-secondary {
    background:var(--bs-gray-700);
}