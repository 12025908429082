.slide {
    padding-top: calc(50rem/16);
    padding-bottom: calc(50rem/16);
    @media (min-width: 768px) {
        padding-top: calc(85rem/16);
        padding-bottom: calc(85rem/16);
    }
    @media (min-width: 1200px) {
        padding-top: calc(125rem/16);
        padding-bottom: calc(125rem/16);
    }
}

.slide.bg-light-grey + .slide.bg-light-grey {
    border-top:.3125rem solid #fff;
    @media (min-width: 768px) {
        border-top:.625rem solid #fff;
    }
}
