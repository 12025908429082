.benefits {
    text-align:center;
    margin-top:1rem;
    padding:0 .875rem;
}

.benefits--slider{
    @media (min-width: 768px) {
        max-width: 33%;
    }
}

.benefits__icon {
    font-size: 2rem;
    color:var(--color-primary);
    @media (min-width: 768px) {
        font-size: 3.125rem;
    }
}
