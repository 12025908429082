.cockpit-container{
    border-radius: var(--border-radius);
    background-color: #fff;
}
.cockpit-container__white{
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    background-color: #fff;
}
.cockpit-container__item{
    padding:1.5rem;

    @media (min-width: 768px){
        padding:2rem;
        height:100%;
    }
}
@media (max-width: 767px){
    .cockpit-container__item--bordered{
        border-top: .25rem solid var(--color-light-grey);
    }
}
@media (min-width: 768px){
    .cockpit-container__item--bordered{
        border-left: .25rem solid var(--color-light-grey);
    }
    .cockpit-container__item--bordered-right{
        border-right: .25rem solid var(--color-light-grey);
    }
}



/* table style grid */
.cockpit-table {
    width:100%;
    margin:0;
    border-collapse: separate;
    border:0;
}
.cockpit-table__row {
    width:100%;
    overflow: hidden;
}
.cockpit-table__col {
    vertical-align: middle;
    text-align:left;
    border: 0;
    padding: .625rem 1rem;
}
.cockpit-table__row--grey .cockpit-table__col {
    background-color: var(--color-light-grey);
}
.cockpit-table__row--white .cockpit-table__col {
    background-color: #fff;
}
.cockpit-table__row--spacer td {
    background-color: #F4F4F5;
    padding:0;
    height:.25rem;
}

.cockpit-table__row--white .cockpit-table__col--sorting{
    background-color: var(--light-grey);
    padding: 0;
    width: 3.125rem;
}
.cockpit-table__input{
    width: 9rem;
}
.cockpit-table__input--sm{
    width: 5rem;
}

@media (min-width: 768px){
    .cockpit-table__col--cta {
        text-align: center;
        width:12rem;
    }
    .cockpit-table__col--cta-large {
        text-align: center;
        width:16rem;
    }
    .cockpit-table__col--cta-x-large {
        text-align: center;
        width:18rem;
    }
    .cockpit-table__col--cta-xx-large {
        text-align: end;
        width: 22rem;
    }
    .cockpit-table__row--white .cockpit-table__col:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
    .cockpit-table__row--white .cockpit-table__col:last-child {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    .cockpit-table__col + .cockpit-table__col {
        border-left: .125rem solid var(--color-light-grey);
    }
}
.cockpit-table__product-img{
    @media (min-width: 768px){
        width: 10rem;
    }
}

.cockpit-table__row--white {
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.cockpit-table__row--spacer td{
    background-color: transparent
}

@media (max-width: 767px){
    .cockpit-table__row--head {
        display: none;
    }
    .cockpit-table__col {
        padding: .5rem 1.5rem;
        display: block;
        width:100%;
    }
    .cockpit-table__row--white .cockpit-table__col:first-child {
        padding-top:1.25rem;
    }
    .cockpit-table__row--white .cockpit-table__col:last-child {
        padding-bottom:1.25rem;
    }
}
.cockpit-table__col--clamped{
    @media (min-width: 768px) {
        width: calc(200rem/16);
    }
    @media (min-width: 1200px) {
        width: calc(300rem/16);
    }
    @media (min-width: 1400px) {
        width: calc(550rem/16);
    }
}
.cockpit-table__clamped{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}


/* status */
.cockpit-item-status{
    position: relative;
    white-space: nowrap;
    padding-left: calc(20rem/16);
}
.cockpit-item-status__dot {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: .6875rem;
    height: .6875rem;
    border-radius: 50%;
    background: var(--color-inactive);
}
.cockpit-item-status__dot--active {
    background: var(--color-active) !important;
}

/* ratings */
.cockpit-item__rating{
    font-family: var(--font-default-bold);
    color: var(--color-active);
}
.cockpit-item__rating--unsatisfied{
    color: var(--color-warning);
}
.cockpit-item__rating--very-unsatisfied{
    color: var(--color-danger);
}

/* info */
.cockpit-item__info-title{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    color: var(--color-text-placeholder);
}
.cockpit-item__label{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    font-family: var(--font-default-bold);
}
ul.cockpit-list{
    margin: 0;
    list-style-type: none;
    padding-left:0;
}
ul.cockpit-list > li:before {
    content: "-";
    margin-right: calc(10rem/16);
}

/* price */
.cockpit-price{
    white-space: nowrap;
}
.cockpit-price--right{
    text-align: right;
}

/*button group*/
.cockpit-button-group {
    display: flex;
    flex-direction: column;
    gap: calc(10rem/16);

    @media (min-width: 768px) {
        flex-direction: row;
        gap: calc(24rem/16);
    }
}
.cockpit-button-group a,
.cockpit-button-group button{
    @media (max-width: 767px){
        width: 100%
    }
}

/* requests */
.cockpit-container__item--pool-request,
.cockpit-container__item--my-request{
    border-radius: var(--border-radius);
    text-align: center;
    font-family: var(--font-default-bold);
}
.cockpit-container__item--pool-request{
    border: var(--color-info) 1px solid;
    color: var(--color-info);
    background-color: rgba(224,115,6,0.1);
}
.cockpit-container__item--my-request{
    border: #1428a0 1px solid;
    color: #1428a0;
    background-color: rgba(20,40,160,0.1);
}