.title-block {
    margin-bottom: calc(14rem/16);
    @media (min-width: 768px) {
        margin-bottom: calc(30rem/16);
    }
}
.title-block__sub-title,
.title-block__title {
    margin-bottom: calc(10rem/16);
    @media (min-width: 768px) {
        margin-bottom: calc(16rem/16);
    }
}


/* cockpit */
.title-block--cockpit small {margin-left:1rem; }