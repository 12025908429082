.return-total-price {
    font-size:1.25rem;
    @media (min-width: 768px) {
        font-size:calc(24rem / 16);
    }
    @media (min-width:1400px) {
        font-size:calc(30rem / 16);
    }
}
@media (min-width: 768px) {
    .return-search-button {
        position: relative;
        top:-2px;
    }
}

.return-state {
    color:#fff;
    font-family: var(--font-default-bold);
    text-align:center;
    display: block;
    margin:0 auto;
    width:calc(12rem/16);
    height:calc(12rem/16);
    background:var(--color-text-default);
    border-radius:50%;
}
.return-state--success,
.return-state--warning {
    width:calc(30rem/16);
    height:calc(30rem/16);
    line-height:calc(30rem/16);
    background:var(--color-danger);
}
.return-state--success {
    font-size:.75rem;
    vertical-align: .2em;
    background:var(--color-success);
}