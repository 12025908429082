/*! purgecss start ignore */
.autocomplete__menu.autocomplete__menu {
    background-color: #fff;
    font-size: calc(16rem/16);
    color: var(--color-font);
    z-index: 900;
    left: 0;
    right: 0;
    border:0;
    min-width:20rem;
    overflow-y: auto;
    border-radius: .5rem;
    margin-top:.125rem;
    max-height:55vh;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}
.autocomplete__dataset {
    position: relative;
    break-inside: avoid;
}

.autocomplete__dataset:not(:first-child){
    margin-top: calc(36rem/16);
}

.autocomplete__dataset-header {
    display: block;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    line-height: 1;
    margin-bottom: calc(12rem/16);
    padding-bottom: calc(12rem/16);
    border-bottom: 1px solid #A9ADAD;
}

.autocomplete__suggestion {
    display: block;
    line-height: 1;
    cursor: pointer;
    margin:1rem 0;
    padding:0;
    text-align: left;
    -webkit-appearance: none;
}
.autocomplete__suggestion-text {
    opacity:.6;
    font-size: calc(14rem/16);
    margin-top:.25rem;
}

.autocomplete__suggestion.cursor {
    background: var(--color-primary);
    color: white;
}

.autocomplete__suggestion.auto-selected,
.autocomplete__suggestion:hover{
    color:var(--color-primary);
}

.autocomplete__highlight{
    background: none;
    padding: 0;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

.autocomplete__suggestion mark{
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: inherit;
    background-color: transparent;
    padding: 0;
}

.autocomplete__menu-wrapper{
    list-style: none;
    padding: 0;
    margin: 0;
}

.autocomplete__results{
    padding: calc(16rem/16);
    @media (min-width: 768px) {
        padding: calc(20rem/16) calc(26rem/16);
    }
}

.autocomplete__dataset:not(:last-child) {
    margin-bottom: 0;
}

.autocomplete__dataset.autocomplete__dataset-customer {
    padding: 20px;
}

.autocomplete__show-more-btn.autocomplete__show-more-btn {
    position: absolute;
    right: 0;
    top:0;
    font-size: calc(14rem/16);
    padding: 0;
    line-height:1.2;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-default);
    border-bottom: .125rem solid var(--color-primary);

    &:hover{
        color: var(--color-primary);
    }
}

/*! purgecss end ignore */