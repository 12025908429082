@media (max-width: 767px){
    .product-aside {
        position: fixed;
        transform:translateX(110%);
        transition: all .3s;
        right:0;
        height:100%;
        width:100%;
        top:0;
        background:var(--color-light-grey);
        z-index:990;
        overflow-x:hidden;
        overflow-y:auto;
        opacity: 0;
        padding-bottom:6rem;
    }
    .product-aside__sticky-btn {
        display: block;
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        border-radius:0;
        z-index:995;
        transform:translateY(130%);
        transition: all .3s;
    }
    .product-aside__sticky-btn.open {
        transform:translateY(0);
    }
    .product-aside.open {
        transform: translateX(0);
        opacity: 1;
    }

    .product-aside__section {
        padding:calc(30rem/16) calc(30rem/16) 0 calc(30rem/16);
    }
    .product-aside__section + .product-aside__section {
        border-top:1px solid var(--color-mid-grey);
    }


    .product-aside__top {
        position: relative;
        overflow: hidden;
        height:var(--header-height-xs);
        background: #fff;
    }
    .product-aside__top-col {
        height:var(--header-height-xs);
    }
    .product-aside__top-col + .product-aside__top-col {
        border-left:.25rem solid var(--color-light-grey);
    }
    .product-aside__top-title {
        font-family: var(--font-default-bold);
        font-size:calc(16rem/16);
        margin-left:2rem;
        margin-top:1.75rem;
    }
    .product-aside__top-close {
        position: relative;
        text-decoration: none;
        display: block;
        width:var(--header-height-xs);
        height:var(--header-height-xs);
        color: var(--color-primary);
    }
    .product-aside__top-close:before {
        font-family: iconfont;
        content: var(--icon-close);
        font-size: 1.5rem;
        line-height:1px;
        position: absolute;
        left:50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        margin-left: -.125rem;
    }
}

.product-aside__filterblock {
    margin-top:1.5rem;
}