/*cockpit-overview*/
.cockpit-tile__container [class^=col]{
    margin-top: calc(60rem/16);
    margin-bottom: calc(30rem/16);

    @media (min-width: 768px) {
        margin-top: calc(80rem/16);
        margin-bottom: calc(30rem/16);
    }
}
.cockpit-tile{
    position: relative;
    padding: calc(80rem/16) calc(23rem/16) calc(47rem/16);
    height: 100%;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    border-radius: var(--border-radius);
}

.cockpit-tile:hover{
    background-color: var(--color-secondary);
    color: white;

    .cockpit-tile__icon-item{
        border: 4px solid var(--color-secondary);
    }
    .btn-link{
        color: white;
    }
    .btn-link:hover{
        border-bottom:.125rem solid var(--color-white);
    }
}
.cockpit-tile .list-inline-item:not(:last-child){
    margin-right: calc(16rem/16);
}
.cockpit-tile__icon{
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translate(-50%, 0);
}
.cockpit-tile__icon-item{
    width: calc(123rem/16);
    height: calc(123rem/16);
    border-radius: calc(62rem/16);
    background-color: var(--color-light-grey);
    border: 4px solid white;

    display: flex;
    justify-content: center;
    align-items: center;
}
.cockpit-tile__icon-item .icon:before{
    font-size: calc(43rem/16);
    width: calc(43rem/16);
    height: calc(43rem/16);
}

/* quicklinkgś */
.cockpit-quicklinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}
.cockpit-quicklinks-list{
    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 1400px) {
        width: 75%;
    }
}
.cockpit-quicklinks-list .list-inline-item{
    @media (max-width: 767px) {
        width: 100%;
    }
}
.cockpit-quicklinks-list .list-inline-item:not(:last-child){
    margin-right: calc(20rem/16);
    margin-bottom: calc(20rem/16);
    margin-top: calc(20rem/16);
}

/*cockpit-news*/
.cockpit-news-area.content-block{
    padding-bottom: calc(50rem/16);
    @media (min-width: 768px) {
        padding-bottom: calc(70rem/16);
    }
    @media (min-width: 1200px) {
        padding-bottom: calc(95rem/16);
    }
}