.hero {
    position: relative;
    width: 100%;
    height:90vh;
    text-wrap: pretty;
    @media (min-width: 992px) {
        height: calc(100vh - var(--header-height));
    }
}
.main-content--transparent-header .hero{
    @media (min-width: 992px) {
        height:100vh;
    }
}
.hero:before, .hero:after  {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(180deg, rgba(41,49,51,0) 0%, rgba(41,49,51,0.1) 36.1%, rgba(41,49,51,0.8) 100%);
    z-index:1;
}
.hero:after {
    top:-1px;
    bottom:auto;
    transform: scaleY(-1);
}
.hero__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:50% 0;
}
.hero__overlay {
    position: absolute;
    left: 0;
    bottom:calc(130rem / 16);
    width: 100%;
    padding:0 1rem;
    color:#fff;
    z-index:3;
    @media (min-width: 1200px) {
        bottom:calc(50rem / 16);
    }
}
.hero__headline.hero__headline {
    margin-bottom:1.5rem;
    @media (min-width: 992px) {
        font-size: calc(70rem / 16);
        width: 66%;
    }
    @media (min-width: 1680px) {
        font-size: calc(85rem / 16);
    }
}

.hero__arrows {
    color:var(--color-primary);
    font-size: calc(20rem / 16);
    margin-top:calc(30rem / 16);
    @media (min-width: 1200px) {
        font-size: calc(25rem / 16);
        margin-top:calc(50rem / 16);
    }

}



.hero__cta {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    background:#fff;
    max-width: 88vw;
    padding: calc(15rem / 16) calc(15rem / 16) calc(15rem / 16)  calc(5rem / 16);
    z-index:990;
    @media (min-width: 1200px) {
        max-width: 40vw;
        padding: calc(20rem / 16) calc(20rem / 16) calc(20rem / 16) 0;
    }
}
.hero__cta:before {
    position: absolute;
    right:100%;
    top:0;
    content:'';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 110px 110px;
    border-color: transparent transparent #FFFFFF transparent;
    transform: rotate(0deg);
}

@media (max-width: 767px) {
    .hero__cta-headline {
        font-size:.875rem;
    }
    .hero__cta-btn {
        padding:.625rem 1rem;
    }
    .hero__cta-btn .icon {
        vertical-align: -.25em;
    }
}

//landingpage
.hero--landingpage{
    @media (min-width: 992px) {
        height:100vh;
    }
}
.hero__cta-landingpage{
    position: absolute;
    top: calc(30rem/16);
    right: 1rem;
    z-index:5;
}