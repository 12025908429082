.pagination{
    display: flex;
    align-items: center;
}
.page-item{
    line-height: calc(18/25);
    font-family: var(--font-default-bold);
}
.page-item .icon{
    font-size: calc(30rem/16);
    @media (min-width: 768px){
        font-size: calc(34rem/16);
    }
}
.page-dots {
    color:var(--color-text-default)
}
.page-dots,
.page-link {
    @media (min-width: 768px){
        padding:0 1rem;
        font-size:calc(20rem/16);
    }
}