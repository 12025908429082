footer {
    position: relative;
    overflow: hidden;
    background:url(static/img/bg-footer.png) 0 50% no-repeat;
    color:#fff;
    @media (min-width: 1200px) {
        background-size:50% auto;
    }

    a:not(.btn) {
        display: inline-block;
        color:#fff;
        text-decoration:none;
        font-family: var(--font-default-bold);
        font-weight: normal;
        margin:.5rem;
        @media (min-width: 768px) {
            margin:0;
        }
    }

    a:not(.btn):hover {
        color:var(--color-dark-grey);
    }
}
footer.footer--no-bg-img {
    background:var(--color-dark);
}
.footer__sponsor-col {
    @media (max-width: 767px) {
        text-align:center;
    }
}

.footer__valantic {
    display: inline-block;
    height:calc(20rem / 16);
    width: auto;
}

footer a.footer-sponsor {
    display: inline-block;
    width:calc(340rem / 16);
    position: relative;
    padding-left:calc(100rem / 16);
    padding-top:.375rem;
    line-height: 1;
    font-size:1.625rem;
    margin:2rem auto 2.875rem auto;
    color:#fff !important;
    text-align: left;
    @media (min-width: 768px) {
        margin:0 0 1rem 3rem;
    }
}
.footer-sponsor__img {
    position:absolute;
    left:0;
    top:0;
    width: calc(72rem / 16);
    height:auto;
}

.footer__link-block {
    padding:1.5rem;

    @media (min-width: 768px) {
        padding:calc(28rem / 16) calc(60rem / 16);
    }
}
.footer__link-block + .footer__link-block {
    border-top:.125rem solid rgba(244,244,245,0.05);
}
.footer__link-col {
    position: relative;
    background:var(--color-dark);
}

@media (min-width: 768px) {
    footer:not(.footer--no-bg-img) .footer__link-col:after {
        content:'';
        display: block;
        position: absolute;
        left:100%;
        top:0;
        bottom:0;
        width:50rem;
        background:var(--color-dark);
    }
    footer:not(.footer--no-bg-img) .footer__link-col:before {
        content:'';
        display: block;
        position: absolute;
        left:100%;
        top:50%;
        margin-top:calc(-1rem/16);
        height:.125rem;
        width:50rem;
        background:rgba(244,244,245,0.05);
        z-index: 1;
    }
}