@media (max-width: 767px) {
    .breadcrumb.breadcrumb {
        margin:1rem 0 .5rem 1rem;
        padding-bottom: 0.75rem;
    }
    .breadcrumb-item{
        display: inline;
    }
    .breadcrumb-item+.breadcrumb-item:before{
        float: none;
        vertical-align: -0.1em;
    }
}

@media (min-width: 768px) {
    .breadcrumb {
        padding:0;
        margin:.75rem 0;
        z-index:10;
        position: relative;
    }
}
.breadcrumb-item+.breadcrumb-item:before {
    font-family: "iconfont";
}
.breadcrumb-item a:hover,
.breadcrumb-item a:focus{
    text-decoration: underline;
}