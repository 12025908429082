/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-light-grey {color: var(--color-light-grey);}
.text-mid-grey {color: var(--color-mid-grey);}
.text-muted, .text-dark-grey {color: var(--color-dark-grey);}
.text-bold {font-family: var(--font-default-bold); }


/* Background Color Helper */
.bg-light-grey {background-color: var(--color-light-grey);}
.bg-mid-grey {background-color: var(--color-mid-grey);}
.bg-dark-grey {background-color: var(--color-dark-grey);}
.bg-success, .bg-warning, .bg-danger, .bg-primary, .bg-secondary {color:var(--color-secondary-contrast)};

/*Icon Helper*/
.icon-primary{
    color: var(--color-primary);
}
.icon-grey{
    color: var(--color-mid-grey);
}
.icon-with-text__center{
    display: flex;
    align-items: center;
}


.d-block[hidden],
.d-md-inline[hidden] {
    display: none !important;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.scroll-margin {
    scroll-margin-top: calc(30rem/16);
    @media (min-width: 768px) {
        scroll-margin-top: calc(80rem/16);
    }
}

.item-rounded,
.video-rounded,
.img-rounded {
    border-radius:var(--border-radius);
}

.img-rounded__top {
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
}

#warning-static-release {
    display: none !important;
}

figure {
    margin:0;
}

/*break container*/
.container.break-container {
position: relative;
    @media (max-width: 1399px) {
        max-width: calc(970rem/16);
        width: 100%;
    }

}
.break-container__right{
    display: none;
    @media (min-width: 1400px) {
        display: block;
        position: absolute;
        right:0;
        width: calc(50% - 2.25rem);
        top: calc(-125rem/16);
        bottom: calc(-125rem/16);
    }
}

.bg-light-grey .break-container__right {
    @media (min-width: 1400px) {
        top:0;
        bottom:0;
    }
}

.break-container__image{
    @media (min-width: 1400px) {
        height: calc(100%);
    }
}

.min-vh-50 {
    min-height: 50vh;
}
@media (min-width: 768px) {
    .w-20-rem {
        width: calc(20rem);
    }
    .w-28 {
        width: 28%;
    }
    .w-33 {
        width: 33%;
    }
}

.form-division,
.division{
    width: 100%;
    border-top-width: .125rem;
    color: var(--color-dark-grey);
    margin: calc(20rem/16) 0 ;
    @media (min-width: 768px) {
        margin: calc(32rem/16) 0;
    }
}
.form-division{
    margin: calc(20rem/16) 0 calc(40rem/16) 0;
}