/* Global variables and settings */
@import './style/mixins.scss';
@import './style/variables.scss';
@import './style/icons.scss';
@import './style/mediaqueries.scss';
@import './style/font-face.scss';


/* General base styling & reset (mostly plain element styling; very low specificity ) */
@import './style/base.scss';
@import './style/base-layout.scss';
@import './style/base-typo.scss';


/* layout */
@import './style/hero.scss';
@import './style/detail-hero.scss';
@import './style/header.scss';
@import './style/main-nav.scss';
@import './style/additional-nav.scss';
@import './style/side-navigation.scss';
@import './style/side-nav-teaser.scss';
@import './style/footer.scss';


/* Generic helper classes (mostly layout) */
@import './style/row--gutter.scss';
@import './style/ratio.scss';


/* Components */
@import './style/autocomplete.scss';
@import './style/alert.scss';
@import './style/btn.scss';
@import './style/form-errors.scss';
@import './style/range-slider.scss';
@import './style/select-dropdown.scss';
@import './style/quickfilter.scss';
@import './style/content-block.scss';
@import './style/slide.scss';
@import './style/wysiwyg.scss';
@import './style/shariff.scss';
@import './style/skip-links.scss';
@import './style/icon-in-text.scss';
@import './style/loading-overlay.scss';
@import './style/loading-spinner.scss';
@import './style/stretch-link.scss';
@import './style/table.scss';
@import './style/video.scss';
@import './style/slider.scss';
@import './style/badge.scss';
@import './style/large-image-teaser.scss';
@import './style/benefits.scss';
@import './style/hotspot.scss';
@import './style/fullscreen-teaser.scss';
@import './style/title-block.scss';
@import './style/content-visibility.scss';
@import './style/teaser.scss';
@import './style/accordion.scss';
@import './style/tabs.scss';
@import './style/image-teaser.scss';
@import './style/product-teaser.scss';
@import './style/job-list.scss';
@import './style/breadcrumbs.scss';
@import './style/modal.scss';
@import './style/items-scrollable.scss';
@import './style/form.scss';
@import './style/number-spinner.scss';
@import './style/service-popup';
@import './style/pagination';
@import './style/systemfinder';
@import './style/systemfinder-detail';
@import './style/overviews';
@import './style/product-grid';
@import './style/product-grid-mobile';
@import './style/grid-teaser';
@import './style/site-nav';
@import './style/lightgallery';
@import './style/sticky-info-area';
@import './style/user-management';
@import './style/cockpit-lists';
@import './style/cockpit-tile';
@import './style/pdp';
@import './style/show-more';
@import './style/retouren.scss';
@import './style/statistics.scss';
@import './style/offer-probability.scss';
@import './style/data-finder-sidenav.scss';
@import './style/checkout.scss';
@import './style/blog.scss';
@import './style/prices.scss';
@import './style/search-overlay.scss';
@import './style/offer-tool.scss';
@import './style/sticky-bar.scss';
@import './style/subitem.scss';

/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
@import './style/utils.scss';
@import './style/themes.scss';
@import './style/theme-cockpit.scss';