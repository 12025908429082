.product-teaser{
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.product-teaser:hover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);

    .product-teaser__img{
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}
.product-teaser__img{
    display: block;
    transition: transform 0.5s ease;
}
.product-teaser__badge-holder {
    position: absolute;
    top: calc(10rem/16);
    right: calc(10rem/16);
    z-index: 1;
    pointer-events: none;
}
.product-teaser__badge{
    display: inline-block;
    padding: calc(8rem/16) calc(20rem/16);
    background-color: var(--color-primary);
    color: #fff;
    font-size: calc(14rem/16);
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
    border-radius:var(--border-radius);
}
.product-teaser__badge--dark {
    background-color: var(--color-dark);
}
.product-teaser__pre-body {
    text-align:right;
    padding: calc(20rem/16) calc(30rem/16);
}
.product-teaser__download-btn {
    display: inline-block;
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-decoration: none;
    color:var(--color-text-default);
    -webkit-appearance: none;
}
.product-teaser__download-btn > .icon  {
    color:var(--color-primary);
    margin-left: calc(7rem/16);
}
.product-teaser__download-btn:hover,
.product-teaser__download-btn:focus {
    color:var(--color-primary);
}
.product-teaser__body{
    padding: calc(25rem/16) calc(30rem/16) calc(30rem/16);
    background-color: #fff;
    border-top: 2px solid var(--color-light-grey);
    z-index: 2;
    display: flex;
    flex-direction: column;
}
.product-teaser__label{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    font-family: var(--font-default-bold);
}
li.product-teaser__label:not(:last-child){
    margin-right: calc(18rem/16);
}
.product-teaser__title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size:1.125rem;
}
.product-teaser__body-list{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (min-width: 1200px) {
        -webkit-line-clamp: 3;
    }
}
.product-teaser__body-list--2col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 calc(15rem/16);
}
.product-teaser__body-list__item{
    font-size: calc(16rem/16);
    white-space: pre-wrap;
}
.product-teaser__body-list__item:last-child{
    margin-bottom: 0;
}
.product-teaser__footer{
    padding: calc(20rem/16) calc(30rem/16);
    background-color: #fff;
    border-top: 2px solid var(--color-light-grey);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) and (max-width: 1200px) {
        flex-direction: column;
    }
}
.product-teaser__footer-price-container{
    @media (min-width: 768px) and (max-width: 1200px) {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: calc(8rem/16);
    }
}
.product-teaser__footer-price{
    font-size: calc(19rem/16);
    font-family: var(--font-default-bold);
}
.product-teaser__footer-strike-price{
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
    text-decoration: line-through;
}