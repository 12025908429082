.anchor-nav{
    padding: calc(15rem/16) 0 0 calc(15rem/16);
    margin-right: calc(-90rem/16);

    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    background-color: var(--color-light-grey);
    border-bottom: solid 2px var(--color-light-grey);

    display:flex;
    //justify-content: center;

    @media (min-width: 768px){
        padding: calc(15rem/16);
        margin-right: calc(-130rem/16);
    }
}
.anchor-nav__list.items-scrollable{
    @media (max-width: 768px){
        padding-bottom: calc(20rem/16);
    }
}
.anchor-nav__link{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    color: var(--color-secondary);

    padding-bottom: calc(16rem/16);

    @media (min-width: 768px){
        padding-bottom: calc(15rem/16);
    }
}
.anchor-nav__link:hover{
    color: var(--color-secondary);
}
.anchor-nav__link.active{
    @media (min-width: 768px) {
        color: var(--color-secondary);
        border-bottom: solid 4px var(--color-primary);
    }
}
.anchor-nav__item:not(:last-child){
    margin-right: calc(30rem/16);

    @media (min-width: 1200px) {
        margin-right: calc(45rem/16);
    }
}