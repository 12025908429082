.header {
    position: fixed;
    left:0;
    right:0;
    top:0;
    background:var(--color-dark);
    padding-right:calc(130rem / 16);
    z-index:1080;
    height:var(--header-height);
    transition: transform 0.2s, background 0.2s;
    @media (max-width:767px) {
        padding-right:calc(90rem / 16);
        height:var(--header-height-xs);
    }
}
.header--transparent {
    background: transparent;
}
.header__logo {
    display: inline-block;
    width:calc(200rem / 16);
    height:auto;
    margin-left:calc(20rem / 16);
    margin-right:calc(30rem / 16);
    @media (max-width:767px) {
        width:calc(120rem / 16);
        margin-left:calc(20rem / 16);
        margin-right:0;
    }
    @media (min-width: 1600px) {
        margin-left:calc(60rem / 16);
    }
}

@media (min-width: 768px) {
    .header--scroll {
        transform: translateY(-110%);
    }
}

.header__right {
    margin-left:auto;
}