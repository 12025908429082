.price__total {
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    font-weight: normal;
    @media (min-width: 768px) {
        font-size:calc(30rem / 16);
    }
}
.price__default-size{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    font-weight: normal;
    @media (min-width: 768px) {
        font-size:calc(18rem / 16);
    }
}