/*TEXT TEASER*/
.teaser-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.teaser{
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.teaser:hover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);

    .teaser__img{
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}
.teaser__img{
    display: block;
    transition: transform 0.5s ease;
}
.teaser__body{
    padding: calc(20rem/16) calc(30rem/16) calc(30rem/16);
    background-color: #fff;
    z-index: 2;

    display: flex;
    flex-direction: column;
}
.teaser__body-content{
    /*font-size: calc(18rem/16);
    line-height: calc(26/18);*/
    white-space: pre-wrap;
}

/*TEASER WIDE*/
.teaser--wide {
    margin:1.5rem 0;
}
.teaser__body--wide{
    @media (min-width: 768px) {
        justify-content: center;

        padding: calc(20rem/16) calc(40rem/16);
    }
    @media (min-width: 1200px) {
        min-height: calc(215rem/16);
    }
}
.teaser__body-news-wide{
    @media (min-width: 768px) {
        width: 70%;
    }
}
.teaser__img-container div{
    @media (min-width: 768px) {
        height: 100%;
    }
}

/*DOWNLOAD TEASER
.teaser__body-download-title{
    font-size: calc(20rem/16);
    line-height: calc(22/20);

    @media (min-width: 768px)  {
        font-size: calc(25rem/16);
    }

    @media (min-width: 1200px) {
        font-size: calc(28rem/16);
        line-height: 1;
    }
}*/

/*SEARCH TEASER
.teaser__body-search-title{
    font-size: calc(18rem/16);
    line-height: calc(20/20);

    @media (min-width: 768px)  {
        font-size: calc(22rem/16);
    }

    @media (min-width: 1200px) {
        font-size: calc(25rem/16);
        line-height: 1;
    }
}*/

/*CONTACT TEASER
.teaser__body-contact-title{
    font-size: calc(20rem/16);
    line-height: calc(22/20);

    @media (min-width: 768px)  {
        font-size: calc(25rem/16);
    }

    @media (min-width: 1200px) {
        font-size: calc(28rem/16);
        line-height: 1;
    }
}*/
.teaser__body-contact{
    display: flex;
    flex-direction: column;
}
.teaser__body-contact .icon{
    font-size: calc(22rem/16);
    width: calc(22rem/16);
    height: calc(22rem/16)
}
.teaser__body-contact li{
    font-family: var(--font-default-bold);
    line-height: calc(18/18);
    margin-bottom: calc(19rem/16);
}
.teaser__body-contact li:last-child{
    margin-bottom: 0;
}
a.teaser__body-contact-item:hover,
a.teaser__body-contact-item:focus{
    color: var(--color-primary);
}

/*JOB TEASER*/
.teaser__body-job-list li{
    margin-bottom: calc(19rem/16);
}
.teaser__body-job-list li .icon{
    font-size: calc(20rem/16);
    width: calc(28rem/16);
    display: flex;
    justify-content: center;

    @media (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.teaser__footer{
    padding: calc(20rem/16) calc(30rem/16);
    background-color: #fff;

    display: flex;
    flex-direction: row;

    @media (min-width: 768px) {
        border-top: 2px solid var(--color-light-grey);
    }
}
.teaser__footer--no-links{
    background-color: rgba(213,30,39,0.1); /*color-primary*/
    font-family: var(--font-default-bold);
    min-height: calc(98rem/16);

    align-items: center;
}

/* HIGHLIGHT TEASER */
.highlight-teaser__body{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    height: 100%;
}
.highlight-teaser__body-subtitle{
    white-space: pre-wrap;

    @media (max-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.highlight-teaser__body-rating{
    font-family: var(--font-default-bold);

    @media (max-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.highlight-teaser__body .list-inline-item{
    line-height: 1;
}
.highlight-teaser__body .icon{
    font-size: calc(18rem/16);
}
.highlight-teaser__body-content{
    white-space: pre-wrap;

    @media (max-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(18/14);
    }
}



