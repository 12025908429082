.content-block.detail-hero {
    position: relative;
    width: 100%;
    margin:0;
    padding:0;
    pointer-events: none;
    @media (min-width: 992px) {
        display: flex;
        justify-content: flex-end;
    }
    @media (max-width: 767px) {
        padding:0 !important;
    }
}

.detail-hero__body {
    pointer-events: initial;
    @media (max-width: 767px) {
        margin:calc(35rem / 16) 1rem;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        margin:calc(85rem / 16) 0;
    }
    @media (min-width: 992px) {
        margin:0;
        position: absolute;
        left:0;
        right:0;
        top:50%;
        transform:translateY(-50%);
    }
}

.detail-hero__right{
    position: relative;
    @media (min-width: 992px) {
        width:50%;
    }
}

@media (min-width: 1200px) {
    .detail-hero--small .detail-hero__right{
        width:35%;
    }
}

.breadcrumb-container + .content-block.detail-hero {
    margin-top:0rem;
    @media (min-width: 992px) {
        margin-top:-3.25rem;
    }
}

.content-block.detail-hero + .content-block.bg-light-grey {
    margin-top:0rem;
}