@font-face {
    font-family: "TradeGothicNextBold";
    src: url('fonts/TradeGothicNextBold/font.woff2') format('woff2'), url('fonts/TradeGothicNextBold/font.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "TradeGothicNextRegular";
    src: url('fonts/TradeGothicNextRegular/font.woff2') format('woff2'), url('fonts/TradeGothicNextRegular/font.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "AlaskaVar";
    src: url('fonts/AlaskaVar/font.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "AlaskaVarBold";
    src: url('fonts/AlaskaVarBold/font.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

/* Important:
*  Font urls have to be relative to the current file or start with the "fonts" alias e.g. "fonts/font-name.woff"
*  The "fonts" alias resolves to /web/static/fonts/
*/