.systemfinder {}

.systemfinder__main-step {
    margin-top: 3.75rem;
}
.systemfinder__step {
    padding: calc(20rem/16) calc(25rem/16);
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    margin-bottom: calc(20rem/16);
    @media (min-width: 768px) {
        padding: calc(40rem/16) calc(50rem/16);
    }
}
.systemfinder__step .form-label {
    display: none;
}
.systemfinder__step .form-group {
    margin:0;
}
.systemfinder__step-label {
    font-family: var(--font-default-bold);
    font-size:calc(18rem/16);
    margin-bottom: .5rem;
    @media (min-width: 768px) {
        font-size:calc(25rem/16);
        margin-bottom:0;
    }
}
.systemfinder__step--disabled .row {
    opacity: .5;
    pointer-events: none;
}