.sidenav-item{
    font-size: calc(18rem/16);
    line-height: calc(20/18);
    font-family: var(--font-default-bold);
    padding: calc(10rem/16) 0;
    text-align: start;
}
.sidenav-level-2,
.sidenav-level-3{
    display: none;
}
.sidenav-item.is-active{
    color: var(--color-primary) !important;
}

/* level 2 */
.is-open + .list-level-2{
    margin: calc(10rem/16) 0 calc(10rem/16) calc(20rem/16);
    border-left: 2px solid var(--color-dark-grey);
}
.is-open + .list-level-2 .sidenav-level-2{
    display: block;
    padding: 0 0 0 calc(15rem/16);
}
.list-level-2:not(:has(.is-open)) .sidenav-level-2 .sidenav-item{
    color: var(--color-text-default);
}
.sidenav-level-2 .sidenav-item{
    font-size: calc(16rem/16);
    line-height: calc(18/16);
    font-family: var(--font-default-bold);
}

/* level 3 */
.is-open + .list-level-3{
    margin: calc(8rem/16) 0 calc(8rem/16) calc(18rem/16);
    border-left: 2px solid var(--color-dark-grey);
}
.is-open + .list-level-3 .sidenav-level-3{
    display: block;
    padding: 0 0 0 calc(17rem/16);
}
.sidenav-level-3 .sidenav-item{
    font-size: calc(14rem/16);
    line-height: calc(14/18);
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
}

/* level 4 */
.is-open + .list-level-4{
    margin: 0 0 0 calc(18rem/16);
    border-left: 2px solid var(--color-dark-grey);
}
.is-open + .list-level-4 .sidenav-level-4{
    display: block;
    padding: 0 0 0 calc(17rem/16);
}
.sidenav-level-4 .sidenav-item{
    font-size: calc(14rem/16);
    line-height: calc(14/18);
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
}

/* level 1 open, level 2 open */
.list-level-1:has(.is-open) .sidenav-level-1:not(.is-open) .sidenav-item,
.list-level-2:has(.is-open) .sidenav-level-2:not(.is-open) .sidenav-item,
.list-level-3:has(.is-open) .sidenav-level-3:not(.is-open) .sidenav-item{
    color: var(--color-dark-grey);
}
.sidenav-level-1.is-open .sidenav-item,
.sidenav-level-2.is-open .sidenav-item, {
    color: var(--color-text-default);
}
.is-open.sidenav-level-1 .icon,
.is-open.sidenav-level-2 .icon,
.is-open.sidenav-level-3 .icon{
    transform: rotate(180deg);
}

/* hover */
.sidenav-item:hover,
.sidenav-level-2 .sidenav-item:hover,
.sidenav-level-3 .sidenav-item:hover,
.sidenav-level-4 .sidenav-item:hover{
    color: var(--color-primary);
}