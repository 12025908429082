button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
}

@media (max-width: 767px) {
    .btn {
        padding: 1rem 1.125rem;
        font-size:.875rem;
    }
}

.input-group .btn {
    padding-top:.5rem;
    padding-bottom:.5rem;
}

.btn-no-styling,
.btn-no-style {
    color: inherit;
}

.btn.btn-md{
    padding: 1rem 1.375rem;

    @media (max-width: 767px) {
        padding: 1rem;
    }
}

.btn.btn-sm{
    padding:.5rem 1rem;
    font-size:.875rem;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus{
    color:#fff;
}

.btn-outline-primary{
    color:var(--color-text-default);
    border-width:.125rem;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus{
    color:#fff;
    background: var(--color-primary);
}

.btn-outline-secondary{
    color:var(--color-text-default);
    border: .125rem solid var(--color-secondary);
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus{
    color:#fff;
    background: var(--color-secondary);
}
.btn-outline-secondary:hover .icon,
.btn-outline-secondary:focus .icon,
.btn-outline-primary:hover .icon,
.btn-outline-primary:focus .icon{
    color:#fff;
}

.btn-icon {
    padding:1rem;
}
.btn-icon > .icon {
    font-size:1.25rem;
    vertical-align: -.3em;
}

.btn-icon-text > .icon{
    margin-right:.25rem;
    font-size:1.25rem;
    vertical-align: -.15em;
}
.btn-icon-text--right > .icon {
    margin-right:0;
    margin-left:.25rem;
}

.btn.btn-link {
    padding:0 0 .5rem 0;
    color:var(--color-text-default);
    text-decoration: none;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom:.125rem solid var(--color-primary);
}
.btn.btn-link:hover,
.btn.btn-link:focus {
    border-bottom:.125rem solid var(--color-text-default);
}
.btn.btn-link--white {
    color:#fff;
    border-bottom:.125rem solid #fff;
}
.btn.btn-link--white:hover,
.btn.btn-link--white:focus {
    color:var(--color-primary);
    border-bottom:.125rem solid var(--color-primary)
}



.btn.btn-link--icon-outside {
    position:relative;
    margin-right:1.625rem;
}
.btn.btn-link--icon-outside > .icon {
    position:absolute;
    right:-1.625rem;
    color:var(--color-primary);
}

.input-group-append .btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
}

.horizontal-btn-group{
    display: flex;
    flex-direction: column;
    gap: 0;
}
.horizontal-btn-group .btn{
    padding: calc(9rem/16) calc(21rem/16) calc(10rem/16);
}
.btn-group .btn-link{
    font-size: calc(16rem/16)
}