.systemfinder-topbar {
    font-size:1rem;
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    overflow:hidden;
    margin:3.5rem 0 2rem 0;
    @media (max-width:767px) {
        font-size:.875rem;
        margin:2rem 0 1.25rem 0;
    }
}
.systemfinder-topbar__item {
    position: relative;
    padding: calc(12rem/16) calc(20rem/16);
}
.systemfinder-topbar__item:before {
    content:'';
    position:absolute;
    right:0;
    top:-2rem;
    bottom:-2rem;
    width:.125rem;
    background:var(--color-light-grey);
    @media (max-width:767px) {
        right:0;
        top:0;
        left:0;
        bottom:auto;
        width: auto;
        height:.125rem;
    }
}

.systemfinder-badge {
    display: inline-block;
    padding: calc(8rem/16) calc(20rem/16);
    background-color: var(--color-primary);
    color: #fff;
    font-size: calc(14rem/16);
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
    border-radius:var(--border-radius);
}

.systemfinder-product {
    font-size:1rem;
    padding:.75rem 0;
    color: #7F8385;
    @media (max-width:767px) {

    }
}


.systemfinder-product + .systemfinder-product {
    border-top:.125rem solid var(--color-light-grey);
}
.systemfinder-product--active {
    color:var(--color-text-default);
}


@media (min-width:768px) {
    .systemfinder-divider {
        height:100%;
        padding-left:1.5rem;
        border-left:.125rem solid var(--color-mid-grey);
    }
}


.systemfinder-image {
    margin:2.5rem 0 1.5rem 0;
    padding:1.5rem;
    border-radius: var(--border-radius);
    background:#fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}


.systemfinder-detail {
    padding:0 0 5rem 0;
    @media (max-width:767px) {
        padding:0 0 2rem 0;
    }
}


.systemfinder-steps {
    margin:0 0 2.5rem 0;
    position:relative;
    top:calc(-30rem / 16);
    @media (max-width:767px) {
        margin:0;
    }
}
.systemfinder-steps__step {
    color:#a9adad;
}
.systemfinder-steps__step>button {
    border:0 !important;
}
.systemfinder-steps__step--active {
    color:var(--color-text-default);
}
.systemfinder-steps__step + .systemfinder-steps__step {
    @media (min-width:768px) {
        margin-left:calc(75rem/16);
    }
}
.systemfinder-steps__nr {
    display: inline-block;
    width:calc(45rem/16);
    height:calc(45rem/16);
    line-height:calc(45rem/16);
    text-align: center;
    border-radius:100%;
    font-family: var(--font-default-bold);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    font-size:1rem;
    background:#fff;
}
.systemfinder-steps__txt {
    display: block;
    margin-top:.375rem;
    font-size:.75rem;
    @media (min-width:768px) {
        font-size:.9375rem;
    }
}


.systemfinder__checkbox {
    display: block;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.systemfinder__checkbox + label {
    cursor:pointer;
    position: relative;
    display: inline-block;
    color:inherit;
    border:0;
    padding:0;
    background:transparent;
    font-family: var(--font-default-bold);
}
.systemfinder__checkbox + label .icon {
    display: inline-block;
    margin-left: .25rem;
    font-size:1.25rem;
    vertical-align: -.15em;
    color:var(--color-text-default);
}
.systemfinder__checkbox + label:hover,
.systemfinder__checkbox + label:focus {
    color:var(--color-text-default);
}
.systemfinder__checkbox:checked + label {
    color:var(--color-text-default);
}
.systemfinder__checkbox:checked + label .icon {
    color:#fff;
    font-size:.625rem;
    width:1.25rem;
    height:1.25rem;
    line-height:1.25rem;
    text-align:center;
    border-radius:100%;
    background:var(--color-success);
    vertical-align: .1em;
    &:before {
        content:var(--icon-check);
    }
}

.rlt-accessory {
    padding-bottom:1rem;
    margin-bottom:1rem;
    border-bottom:.125rem solid var(--color-mid-grey);
}
.rlt-sub-accessory {
    margin-top:1rem;
}

.systemlist {

}
.systemlist__title {
    padding-bottom:1rem;
    margin-bottom:1rem;
    border-bottom:.125rem solid var(--color-mid-grey);
}