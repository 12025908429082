.side-nav-teaser {
    position: relative;
    overflow: hidden;
    max-width: 22rem;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
}

.side-nav-teaser__body {
    color:#fff;
    padding: 2rem;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0,0,0,.25);
    transition: background .3s;
}

.side-nav-teaser:hover .side-nav-teaser__body,
.side-nav-teaser:focus .side-nav-teaser__body {
    background: rgba(0,0,0,.35);
}