.offer-probability__container{
    width: 100%;
    height: calc(11rem/16);
    background-color: white;
    border: 1px solid rgba(41,49,51,0.2);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.offer-probability__percentage{
    width: 5%;
    height: 100%;
    background-color: var(--color-info);
    border: 1px solid rgba(41,49,51,0.2);

    transition: all 1s linear;
}