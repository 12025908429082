.table-wrapper{
    margin-bottom: 2rem;
    hyphens:none;
}
.wysiwyg table,
.table,{
    width: 100% !important; /*override inline css width*/
    max-width: 100% !important;
    margin:0;
    border:0;
}

.wysiwyg table:not(.cockpit-table) > thead > tr > th,
.wysiwyg table:not(.cockpit-table) > tbody > tr > th,
.wysiwyg table:not(.cockpit-table) > tfoot > tr > th,
.wysiwyg table:not(.cockpit-table) > thead > tr > td,
.wysiwyg table:not(.cockpit-table) > tbody > tr > td,
.wysiwyg table:not(.cockpit-table) > tfoot > tr > td,
.table:not(.cockpit-table) > thead > tr > th,
.table:not(.cockpit-table) > tbody > tr > th,
.table:not(.cockpit-table) > tfoot > tr > th,
.table:not(.cockpit-table) > thead > tr > td,
.table:not(.cockpit-table) > tbody > tr > td,
.table :not(.cockpit-table)> tfoot > tr > td{
    vertical-align: middle;
    padding: .5rem .75rem;
    text-align:left;
    background-color: #fff;
    border: .125rem solid var(--color-light-grey);
    @media (min-width: 768px){
        padding: .875rem 1.875rem;
    }
}

.wysiwyg table:not(.cockpit-table) > tbody > tr > td.text-right{
    text-align: right;
}

.wysiwyg table:not(.cockpit-table) > thead > tr > td,
.wysiwyg table:not(.cockpit-table) > thead > tr > th,
.wysiwyg table:not(.cockpit-table) > tbody > tr > th,
.wysiwyg table :not(.cockpit-table)> tfoot > tr > th,
.table:not(.cockpit-table) > thead > tr > td,
.table:not(.cockpit-table) > thead > tr > th,
.table:not(.cockpit-table) > tbody > tr > th,
.table:not(.cockpit-table) > tfoot > tr > th {
    background: var(--color-light-grey);
    font-family: var(--font-default-bold);
    font-weight: normal;
}

/* adaptions with background area */

.bg-light-grey .wysiwyg table:not(.cockpit-table) > thead > tr > td,
.bg-light-grey .wysiwyg table:not(.cockpit-table) > thead > tr > th,
.bg-light-grey .wysiwyg table:not(.cockpit-table) > tbody > tr > th,
.bg-light-grey .wysiwyg table:not(.cockpit-table) > tfoot > tr > th,
.bg-light-grey .table > thead > tr > td,
.bg-light-grey .table > thead > tr > th,
.bg-light-grey .table > tbody > tr > th,
.bg-light-grey .table > tfoot > tr > th {
    background: #e7e7e7;
}

/* technical data accordion table */
.table__technical-data tr:nth-child(even) td:not(.table__technical-data__inner){
    background-color: var(--color-light-grey) !important;
    border-right: .125rem solid #fff !important;
}
.table__technical-data tr:nth-child(even) td:last-child{
    border-right: .125rem solid var(--color-light-grey) !important;
}


/* slider table */
.table-slider{
    position: relative;
}

.table-slider__container{
    box-shadow: 5px 0 20px 0px rgba(0,0,0,.1);
    white-space: nowrap;
    overflow:auto;
    scroll-behavior: smooth;
}
.table-slider__container::-webkit-scrollbar {
    display: none;
}
.table-slider__container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.table-slider__table{
    table-layout: fixed;
    text-wrap: balance;
}

.table-slider__table > thead > tr > th,
.table-slider__table > tbody > tr > th,
.table-slider__table > tfoot > tr > th,
.table-slider__table > thead > tr > td,
.table-slider__table > tbody > tr > td,
.table-slider__table > tfoot > tr > td{
    vertical-align: middle;
    padding: .5rem .75rem;
    text-align:left;
    background-color: #fff;
    border: .125rem solid var(--color-light-grey);
    @media (min-width: 768px){
        padding: .875rem 1.875rem;
    }
}

.table-slider__table tr>th:first-child,
.table-slider__table tr>td:first-child {
    @media (min-width: 768px) {
        position: sticky;
        left: 0;
    }
}
.table-slider__table:not(.table-slider__table--family) tr>th:first-child::after,
.table-slider__table:not(.table-slider__table--family) tr>td:first-child::after {
    @media (min-width: 768px) {
        display: block;
        content: '';
        box-shadow: 5px 5px 20px 0px rgba(0,0,0,.2);
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
.table-slider__arrow-container{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left:0;
    right:0;
    width: auto;
    transform: translateY(-50%);

    @media (max-width: 1400px) {
        justify-content: flex-start;
        top: unset;
        left:unset;
        right:0;
        bottom: 0;
        width: auto;
        transform: translateY(0);
    }

    @media (min-width: 1680px) {
        left:calc(-60rem/16);
        right:calc(-60rem/16);
    }
}

.table-slider .table-slider__arrow-container .table-slider__left,
.table-slider .table-slider__arrow-container .table-slider__right {
    font-size: calc(36rem/16);
    line-height: calc(10rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 0;
    color: var(--color-primary);
    background-color: var(--color-white);

    @media (max-width: 2400px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
        font-size: calc(24rem/16);
    }
}
.table-slider .table-slider__arrow-container .table-slider__left:hover,
.table-slider .table-slider__arrow-container .table-slider__left:focus,
.table-slider .table-slider__arrow-container .table-slider__right:hover,
.table-slider .table-slider__arrow-container .table-slider__right:focus {
    color: var(--color-black);
    background-color: var(--color-white);
}
.table-slider .table-slider__arrow-container .table-slider__left:disabled,
.table-slider .table-slider__arrow-container .table-slider__right:disabled{
    color: var(--color-light-grey);
    background-color: var(--color-white);
}