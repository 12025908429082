.user-management__container{
    padding: 0 calc(20rem/16) 0;
}
.user-management__container--white{
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    background-color: #fff;
}

/* address block */
.user-management__item{
    padding: calc(20rem/16) 0;

    @media (min-width: 768px) {
        padding: calc(32rem/16) calc(20rem/16) calc(24rem/16);
    }
}
.user-management__container [class^=col]:not(:last-child){
    border-bottom: 2px solid var(--color-light-grey);

    @media (min-width: 768px) {
        border-right: 2px solid var(--color-light-grey);
        border-bottom: 0;
    }
}

/* profile list */
table.user-management__table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 calc(8rem/16);
}
.user-management__table th{
    font-size: calc(20rem/16);
    @media (min-width: 768px) {
        font-size:calc(22rem / 16);
    }
}
.user-management__table td:not(:last-child) {
    border-right: 2px solid var(--color-light-grey);
}
.user-management__table{
    td:first-child { border-top-left-radius: calc(6rem / 16); }
    td:last-child { border-top-right-radius: calc(6rem / 16); }

    td:first-child { border-bottom-left-radius: calc(6rem / 16); }
    td:last-child { border-bottom-right-radius: calc(6rem / 16); }

    tr:first-child td { border-top-style: solid; }
    tr td:first-child { border-left-style: solid; }
}
.user-management__table-col-big{
    width: 20%;
}
.user-management__table-col-small{
    width: 10%;
}
.user-management__profile-item{
    align-content: center;
    height: 100%;
    padding: calc(15rem/16) calc(40rem/16);

}
.user-management__profile-item--status{
    position: relative;
    padding-left: calc(60rem/16);
}
.user-management__profile-status {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%);
    width: .6875rem;
    height: .6875rem;
    border-radius: 50%;
    background: var(--color-inactive);
}
.user-management__profile-status--active {
    background: var(--color-active) !important;
}