.sticky-bar{
    position: sticky;
    bottom: 0;
    z-index: 900;
    background: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);

    @media (max-width: 768px) {
        display: none;
    }
}

.sticky-bar__container{
    //display: flex;
    //justify-content: space-between;
    padding: calc(25rem/16) 0;
}

.sticky-bar--visible{
    opacity: 1;
    transform: translateY(0%);
    transition: all 1s;
}
.sticky-bar--hidden{
    opacity: 0;
    transform: translateY(-240%);
    transition: all 0.5s;
    z-index:0;
    pointer-events: none;
}