.bg-light-grey + .content-block.fullscreen-teaser-area,
.content-block.fullscreen-teaser-area + .bg-light-grey,
.content-block.fullscreen-teaser-area + .content-block.fullscreen-teaser-area{
    margin:0 !important;
}
.content-block:last-of-type.fullscreen-teaser-area{
    margin-bottom: 0 !important;
}
.fullscreen-teaser {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    text-align: center;
    color: #fff;
    padding:calc(65rem/16) 0;
    @media (min-width: 768px) {
        padding:calc(100rem/16) 0;
    }
    @media (min-width: 1200px) {
        padding:calc(181rem/16) 0;
    }
}
.fullscreen-teaser--no-icon{
    padding:calc(80rem/16) 0;

    @media (min-width: 1200px) {
        padding:calc(200rem/16) 0;
    }
}
.fullscreen-teaser__body {
    position: relative;
    z-index:2;
    a:not(.btn) {
        color: #fff;
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.fullscreen-teaser__icon {
    font-size: calc(30rem/16);
    @media (min-width: 768px) {
        font-size: calc(50rem/16);
    }
}
.fullscreen-teaser:after {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    width:auto;
    height:auto;
    z-index:1;
}