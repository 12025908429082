.input-group--number-spinner{
    height: calc(49rem/16);
    min-width:8rem;
}
.input-group--number-spinner .number-spinner__btn{
    height: 100%;
    padding: calc(14rem/16);
}
.input-group--number-spinner .number-spinner__btn:disabled{
    border: 1px solid rgba(41,49,51,.2);
    color: rgba(41,49,51,.2);
}
.input-group-prepend .number-spinner__btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.number-spinner__input{
    border-radius: 0;
    width: calc(98rem/16);
    padding-left:.5rem;
    padding-right:.5rem;
}