.loading-overlay-container {
    position: relative;
    /*overflow: hidden;*/
}
/*.loading-overlay-container--form{
    padding: calc(10rem/16);
    margin: calc(-10rem/16);
}*/
.loading-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.5rem;
    right: -2.125rem;
    bottom: -1.5rem;
    left: -2.125rem;
    background: rgba(255, 255, 255, .7);
    z-index: 5;

    @media (max-width: 767px) {
        top: -1rem;
        bottom: -1rem;

    }
}
.loading-cubes__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* HTML: <div class="loader"></div> */
.loader-1 {
    height: 50px;
    aspect-ratio: 2;
    --c:no-repeat linear-gradient(var(--color-primary) 0 0);
    background:var(--c),var(--c),var(--c);
    background-size: 26% 50%;
    background-position: 0, 50%, 100%;
    animation: l1 1s infinite cubic-bezier(0.5,150,0.5,-150);
}
@keyframes l1 {
    0%   {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%  , calc(2*100%/3) 50%  , calc(3*100%/3) 50%}
    100% {background-position: calc(0*100%/3) 51%, calc(1*100%/3) 50.5%, calc(2*100%/3) 49.5%, calc(3*100%/3) 49%}
}


/* HTML: <div class="loader"></div> */
.loader-2 {
    width: 85px;
    height: 35px;
    --g1:conic-gradient(from  90deg at 3px  3px ,var(--color-primary) 90deg,var(--color-primary) 0);
    --g2:conic-gradient(from -90deg at 22px 22px,var(--color-primary) 90deg,var(--color-primary) 0);
    background:var(--g1),var(--g1),var(--g1), var(--g2),var(--g2),var(--g2);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    animation: l2 1s infinite alternate;
}
@keyframes l2 {
    0%   {background-position:0 50% ,50% 50% ,100% 50% }
    20%  {background-position:0 0   ,50% 50% ,100% 50% }
    40%  {background-position:0 100%,50%  0  ,100% 50% }
    60%  {background-position:0 50% ,50% 100%,100% 0   }
    80%  {background-position:0 50% ,50% 50% ,100% 100%}
    100% {background-position:0 50% ,50% 50% ,100% 50% }
}

/* HTML: <div class="loader"></div> */
.loader-3 {
    width: 85px;
    height: 25px;
    --g1:conic-gradient(from  90deg at left   3px top   3px, var(--color-primary) 90deg, var(--color-primary) 0);
    --g2:conic-gradient(from -90deg at bottom 3px right 3px, var(--color-primary) 90deg, var(--color-primary) 0);
    background:var(--g1),var(--g1),var(--g1), var(--g2),var(--g2),var(--g2);
    background-position: left,center,right;
    background-repeat: no-repeat;
    animation: l3 1s infinite;
}
@keyframes l3 {
    0%   {background-size:25px 100%,25px 100%,25px 100%}
    20%  {background-size:25px 50% ,25px 100%,25px 100%}
    40%  {background-size:25px 50% ,25px 50% ,25px 100%}
    60%  {background-size:25px 100%,25px 50% ,25px 50% }
    80%  {background-size:25px 100%,25px 100%,25px 50% }
    100% {background-size:25px 100%,25px 100%,25px 100%}
}