//IMAGE TEASER
.image-teaser{
    position: relative;
    border-radius:var(--border-radius);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    width: 100%;
    display: block;
}
.image-teaser--clickable{
    cursor: pointer;
}
.image-teaser:hover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);

    .image-teaser__img{
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}
.image-teaser__img{
    display: block;
    transition: transform 0.5s ease;
}
.image-teaser__img-container:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .2;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
}
.image-teaser__body{
    position: absolute;
    left: 30px;
    bottom: 30px;
    right: 20px;
    z-index: 2;
}
.image-teaser__body-label{
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    font-family: var(--font-default-bold);
    color: #fff;
}

