.job-list{
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    border-radius:var(--border-radius);
}
.job-list__item{
    padding: calc(25rem/16) calc(30rem/16) calc(30rem/16);

    @media (min-width: 768px){
        padding: calc(20rem/16) calc(30rem/16);
    }
}
.job-list__item:hover{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.job-list__item:not(:last-child){
    border-bottom: 2px solid var(--color-light-grey);
}
.job-list__title{
    white-space: pre-wrap;

    @media (max-width: 768px) {
        font-size: calc(22rem/16);
    }
}
.job-list .list-inline-item:not(:last-child){
    margin-right: calc(30rem/16);
}

@media (max-width: 767px) {
    .job-list .list-inline-item{
        width:100%;
    }
}
.job-list__location {
    @media (min-width: 768px) {
        width:11rem;
    }
}
.job-list__place {
    @media (min-width: 768px) {
        width:14rem;
    }
    @media (min-width: 1200px) {
        width:18rem;
    }
}
.job-list__work {
    @media (min-width: 768px) {
        width:13rem;
    }
    @media (min-width: 1200px) {
        width:16rem;
    }
}
.job-list .list-inline-item .icon{
    font-size: calc(20rem/16);
    width: calc(28rem/16);
    display: flex;
    justify-content: center;

    @media (min-width: 768px){
        font-size: calc(22rem/16);
    }
}
.job-list .list-inline-item{
    @media (max-width: 767px) {
        line-height: 1.2;
        margin-bottom: calc(8rem/16);
    }
}
.job-list__button{
    @media (min-width: 768px){
        display: flex;
        justify-content: end;
        align-items: center;
    }
}
.job-list-filter .wysiwyg{
    white-space: pre-line;
}