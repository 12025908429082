.modal .modal-dialog:not(.modal-lg) {
    max-width: 78rem;
}
.modal .modal-content {
    border:0;
}
.modal .modal-footer,
.modal .modal-header{
    padding:1.5rem 2.5rem;
    @media (max-width: 767px) {
        padding:1.25rem 1.5rem;
    }
}
.modal .modal-body {
    padding:1.5rem 2.5rem;
    @media (max-width: 767px) {
        padding:1.25rem 1.5rem;
    }
}


.modal .btn-close {
    background:none;
    font-size:1.5rem;
    line-height:1;
    padding-right:1rem;
    color:var(--color-text-default);
}
.modal .btn-close:before {
    font-family: iconfont;
    content:var(--icon-close);
}
.modal .modal-container__grey{
    background-color: var(--color-light-grey);
    padding: calc(16rem/16);
    border-radius: var(--border-radius);
}
