html {
    /* Table scaling */
    @media (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;
    hyphens: auto;
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    display: block;
    color: inherit;
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, .h1 {
    font-size: calc(30rem/16);
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size:calc(36rem / 16);
    }
    @media (min-width:1400px) {
        line-height: 1;
        font-size:calc(40rem / 16);
    }
}
h2, .h2 {
    font-size: calc(22rem/16);
    @media (min-width: 768px) {
        font-size:calc(24rem / 16);
    }
}
h3, .h3 {
    font-size: calc(20rem/16);
    text-transform: none;
    @media (min-width: 768px) {
        font-size:calc(22rem/16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    @media (min-width: 1400px) {
        font-size:calc(20rem / 16);
    }
}
h5, .h5 {
    font-size: calc(17rem/16);
    @media (min-width: 1400px) {
        font-size:calc(19rem / 16);
    }
}
h6, .h6 {
    font-size: calc(14rem/16);
    text-transform: uppercase;
}

table{
    word-break: initial;
}
