html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

#main-content {
    padding-top: var(--header-height);
    min-height:80vh;
    @media (max-width:767px) {
        min-height:75vh;
        padding-top:var(--header-height-xs);
    }
}
#main-content.pdp{
    padding-top: calc(var(--header-height) + calc(64rem/16));
    @media (max-width:767px) {
        padding-top:calc(var(--header-height-xs) + calc(61rem/16));
    }
}

#main-content.main-content--transparent-header {
    padding-top: 0;
}

.container.container {
    max-width: calc(1620rem/16);
    width: 100%;
}
.container.container-medium {
    max-width: calc(1200rem/16);
    width: 100%;
}
.container.container-small {
    max-width: calc(970rem/16);
    width: 100%;
}
.container.container-x-small {
    max-width: calc(620rem/16);
    width: 100%;
}