.nav-tabs {

}
.nav-tabs .nav-item {
    margin:0 .75rem 0 0;
    @media (min-width: 768px) {
        margin:0 1.125rem;
    }
}
.nav-tabs .nav-link {
    font-family: var(--font-default-bold);
    font-weight: normal;
    padding:.5rem 0;
    color: var(--color-dark-grey);
    margin-bottom:.375rem;
    border-bottom:.25rem solid transparent;
    @media (min-width: 768px) {
        font-size:1.25rem;
        margin-bottom:0;
    }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
    background: transparent;
    color: var(--color-text-default);
    border-bottom:.25rem solid var(--color-primary);
}

/* tab content */
.tab-content {
    margin-top:1rem;
    @media (min-width: 768px) {
        margin-top:2rem;
    }
}