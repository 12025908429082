.hide-content {
    overflow: hidden;
    line-height: 1em;
    height: 220px;
    position: relative;
}
.hide-content::after {
    display: block;
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 220px;
    background: linear-gradient(180deg, rgba(244,244,245,0) 0%, rgba(244,244,245,0) 5%, rgba(244,244,245,0) 58%, rgba(244,244,245,0.07886904761904767) 79%, rgba(244,244,245,1) 99%);
}

.show-content {
    line-height: 1em;
    height: auto;
}

.hide-accordion .accordion-item:nth-child(n+4){
     display: none;
}
