.range-slider {
    position: relative;
}
.btn.range-slider__btn {
    font-size:.625rem;
    margin-top:.375rem;
    @media (min-width: 768px) {
        margin-top:.75rem;
    }
}
.range-slider__label {
    font-size:inherit;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}
.range-slider__values {
    font-size: calc(14rem / 16);
    color: var(--color-text-default);
}
.range-slider__slider {
    height: calc(4rem / 16);
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: var(--color-mid-grey);
    padding: 0 calc(12rem / 16);
    width: 100%;
    margin:1rem 0 1.375rem 0;
}

.range-slider__slider .noUi-handle {
    width: calc(20rem/16);
    height: calc(20rem/16);
    background: var(--color-primary);
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    top: calc(-8rem/16);
    right: calc(-12rem/16);
    border: 0;
}
.range-slider__slider .noUi-handle-lower {
    right: calc(-8rem / 16);
}
.range-slider__slider .noUi-handle:before,
.range-slider__slider .noUi-handle:after {
    content: none;
}
.range-slider__slider .noUi-handle:focus {
    outline: none;
}
.range-slider__slider .noUi-connect {
    background: var(--color-text-default);
    box-shadow: none;
    height: calc(4rem / 16);
}

.range-slider__value {
    position: absolute;
    top:3.625rem;
    left:0;
    font-size:1rem;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}
.range-slider__value + .range-slider__value {
    left:auto;
    right:0;
}

.range-slider__input {
    background: none;
    border: 0;
    color: currentColor;
    cursor: default;
    width: calc(20rem/16);
    padding: 0;
}
