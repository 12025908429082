/* request overview */
.statistics__requests .icon:before{
    font-size: calc(18rem/16);
    width: calc(18rem/16);
    height: calc(18rem/16);
    color: var(--color-secondary);
}
.statistics__request-bar{
    height: calc(4rem/16);
    width: 100%;
    opacity: 1;
    border-radius: var(--border-radius);
    color: var(--color-secondary);
    background-color: var(--color-secondary);
}
.statistics__requests--answered,
.statistics__requests--answered .icon:before,
.statistics__requests--answered .statistics__request-bar{
    color: #7F8385;
}
.statistics__requests--answered .statistics__request-bar{
    background-color: #7F8385;
}
.statistics__requests--forwarded,
.statistics__requests--forwarded .icon:before,
.statistics__requests--forwarded .statistics__request-bar{
    color: var(--color-active);
}
.statistics__requests--forwarded .statistics__request-bar{
    background-color: var(--color-active);
}
.statistics__requests--open,
.statistics__requests--open .statistics__request-bar{
    color: var(--color-primary);
}
.statistics__requests--open .statistics__request-bar{
    background-color: var(--color-primary);
}
.statistics__requests--open .statistics__dot-container{
    position: relative;
    white-space: nowrap;
    padding-left: calc(20rem/16);
}
.statistics__requests--open .statistics__dot{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: .6875rem;
    height: .6875rem;
    border-radius: 50%;
    background: var(--color-inactive);
}

/* statistics ratings */
.statistics__rating-title{
    font-size: calc(14rem/16);
    line-height: calc(14/18);
    margin-bottom: calc(4rem/16);
}

/* statistics course */
.statistics__wrapper{
    @media (max-width: 768px) {
        overflow-x: scroll;
    }
}
.statistics__container{
    background: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    padding: calc(10rem/16);
    border-radius: var(--border-radius);
    width:100%;
    height:33rem;

    @media (min-width: 768px) {
        padding: calc(50rem/16) calc(123rem/16) calc(50rem/16) calc(63rem/16);
    }
    @media (max-width: 767px) {
        width: 800px;
        height: 400px;
    }
}

.statistics__tooltip-container{
    background: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    padding: calc(5rem/16) calc(22rem/16);
    border-radius: var(--border-radius);
}
.statistics__tooltip-container tbody,
.statistics__tooltip-container td,
.statistics__tooltip-container tfoot,
.statistics__tooltip-container th,
.statistics__tooltip-container thead,
.statistics__tooltip-container tr{
    border: calc(10rem/16) solid white;
}
.statistics__tooltip-title{
    text-align: center;
    font-size: calc(18rem/16);
    font-family: var(--font-default);
}
.statistics__tooltip-content{
    display: block;
    min-width: calc(52rem/16);
    color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    border-radius: var(--border-radius);
    padding: calc(5rem/16) calc(10rem/16);
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    font-family: var(--font-default-bold);
    text-align: center;
}

/*statistics info */
.statistics__info{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    background: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    padding: calc(15rem/16) calc(20rem/16);
    border-radius: var(--border-radius);

    @media (min-width: 768px) {
        padding: calc(30rem/16) calc(40rem/16);
    }
}

.statistics__legend{
    position: relative;
    white-space: nowrap;
    padding-left: calc(40rem/16);
}
.statistics__legend-item {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: calc(2rem/16);
    background: var(--color-dark-grey);
}
.statistics__legend-item--current {
    background: var(--color-inactive);
}
