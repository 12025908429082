.offer-tool-form__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 24px 0;
    grid-template-areas:
    "Kommision"
    "Name"
    "Telefon"
    "Gültig-bis"
    "Einleitungstext"
    "Abschlusstext"
    "Kundenadresse"
    "Absender";

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 24px 37px;
        grid-template-areas:
    "Kommision Einleitungstext Kundenadresse"
    "Name Einleitungstext Kundenadresse"
    "Telefon Abschlusstext Absender"
    "Gültig-bis Abschlusstext Absender";
    }
}
.offer-tool-form--commission { grid-area: Kommision; }
.offer-tool-form--name { grid-area: Name; }
.offer-tool-form--phone { grid-area: Telefon; }
.offer-tool-form--valid { grid-area: Gültig-bis; }
.offer-tool-form--intorductory { grid-area: Einleitungstext; }
.offer-tool-form--final { grid-area: Abschlusstext; }
.offer-tool-form--customer { grid-area: Kundenadresse; }
.offer-tool-form--sender { grid-area: Absender; }

.offer-tool-list .list-inline-item:not(:last-child) {
    @media (max-width: 767px) {
        margin-bottom: calc(16rem/16);
    }
}