@import "node_modules/bootstrap/scss/mixins/buttons";
@import "node_modules/bootstrap/scss/functions";
@import "../libs/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables";

/*
    !!
    some variables are overwritten in inline-layout.scss
    !!
 */

$primaryThemes: (
    "samcool": (
        primary: #1428a0,
        primaryTextHover: #00106F,
    )
);

@each $themeName, $primaryTheme in $primaryThemes {

    $primary: map-get($primaryTheme, primary);
    $primaryTextHover: map-get($primaryTheme, primary);

    .theme-#{$themeName},
    :not(body).theme-#{$themeName} { // increases the specificty of non-body-classes (area-themes) so an area class overrides the body-theme

        --color-primary: #{$primary};
        --bs-primary: #{$primary};
        --bs-primary-rgb: #{to-rgb($primary)};
        --color-primary-text-hover: #{$primaryTextHover};

        .btn-primary {
            @include button-variant($primary, $primary);
        }

        .btn-outline-primary {
            @include button-outline-variant($primary, $primary);
        }

    }
}