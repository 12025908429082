.additional-nav {
    position: relative;
}
.additional-nav__item {
    position: relative;
    display: inline-block;
    margin:0;
}

@media (min-width:768px) {
    .additional-nav__item + .additional-nav__item:not(.additional-nav__item--menu){
        border-left:.125rem solid #7F8385;
    }
    .header--transparent .additional-nav__item + .additional-nav__item:not(.additional-nav__item--menu){
        border-left:.125rem solid transparent;
    }
}
.additional-nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 2rem;
    height:var(--header-height);
    font-size:1.125rem;
    color: #fff;
    text-decoration: none;
    transition: opacity .3s;
    position: relative;
    @media (max-width:767px) {
        padding: 1.5rem 1rem;
        height:var(--header-height-xs);
    }
    @media (max-width:400px) {
        padding: 1.5rem .75rem;
    }
}
.additional-nav__link.open:after {
    content:'';
    display: block;
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    border-bottom:.25rem solid var(--color-primary);
}
.additional-nav__link .additional-nav__link-icon {
    width:calc(26rem / 16);
    height:calc(26rem / 16);
    @media (max-width:767px) {
        width:1.25rem;
        height:1.25rem;
    }
}
.additional-nav__link .additional-nav__link-icon {
    fill: #fff;
}

@media (min-width:768px) {
    .additional-nav__link--w-text .additional-nav__link-icon {
        margin-right:.75rem;
    }
}

.additional-nav:hover .additional-nav__link{
    opacity: .55;

}
.additional-nav:hover .additional-nav__link:hover,
.additional-nav:hover .additional-nav__link:focus {
    color: #fff;
    opacity: 1;
}

.additional-nav__link--search .additional-nav__link-icon--close,
.additional-nav__link--search.open .additional-nav__link-icon--search {
    display: none;
}
.additional-nav__link--search.open .additional-nav__link-icon--close {
    display: block;
    color:var(--color-primary);
    font-size:calc(27rem / 16);
    @media (max-width:767px) {
        font-size:1.25rem;
    }
}


.main-nav-toggle {
    position: absolute;
    right:0;
    top:0;
    display: inline-block;
    color: #fff;
    background:var(--color-primary);
    width:calc(130rem / 16);
    height:var(--header-height);
    text-align: center;
    transition: background 0.3s;
    @media (max-width:767px) {
        width:calc(80rem / 16);
        height:var(--header-height-xs);
    }
}
.header--transparent .main-nav-toggle {
    border-bottom-left-radius: calc(10rem/16);
}
.main-nav-toggle:hover,
.main-nav-toggle:focus {
    background:var(--color-primary-dark);
}
.main-nav-toggle__icon {
    position: absolute;
    top: 2rem;
    width: 3rem;
    height: 1.375rem;
    margin: 0 auto;
    transition: .5s ease-in-out;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width:767px) {
        width: 2.5rem;
        top: 1.25rem;
    }
}

.main-nav-toggle__icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 3rem;
    background: #fff;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    @media (max-width:767px) {
        width: 2.5rem;
    }
}
.main-nav-toggle__icon span:nth-child(1) {
    top: 0;
    transform-origin: left center;
}
.main-nav-toggle__icon span:nth-child(2) {
    top: 10px;
    transform-origin: left center;
    @media (max-width:767px) {
        top: 8px;
    }
}
.main-nav-toggle__icon span:nth-child(3) {
    top: 20px;
    transform-origin: left center;
    @media (max-width:767px) {
        top: 16px;
    }
}
.open .main-nav-toggle__icon {
    width: 1.3rem;

    @media (max-width: 767px) {
        width: 1.4rem
    }
}
.open .main-nav-toggle__icon span:nth-child(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 1px;
    width: 1.8rem;

    @media (max-width:767px) {
        transform: rotate(40deg);
        width: 1.6rem
    }
}
.open .main-nav-toggle__icon span:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.open .main-nav-toggle__icon span:nth-child(3) {
    transform: rotate(-45deg);
    left: 1px;
    width: 1.8rem;

    @media (max-width:767px) {
        transform: rotate(-40deg);
        width: 1.6rem
    }
}
.main-nav-toggle__label {
    position: absolute;
    left:0;
    right:0;
    bottom:1rem;
    text-transform: uppercase;
    font-size: .9375rem;
    font-family: var(--font-default-bold);
    letter-spacing:1px;
    @media (max-width:767px) {
        font-size:calc(12rem / 16);
        bottom:.9375rem;
    }
}