.btn-select-style {
    position: relative;
    display: block;
    width: 100%;
    padding: .75rem 3.375rem .75rem 1.125rem;
    font-size: 1.125rem;
    line-height:1;
    border: 1px solid var(--color-mid-grey);
    background: #fff;
    color: inherit;
    cursor: pointer;
    border-radius:var(--border-radius);
    text-align: left;
}
.btn-select-style:after {
    content:var(--icon-arrow-right);
    font-family: iconfont;
    font-size: 1.375rem;
    color:var(--color-primary);
    border:0;
    width:auto;
    height:auto;
    position: absolute;
    right:1rem;
    top:.625rem;
    transform: rotate(90deg);
}

.dropdown-menu-select-style {
    width: 100%;
    border-radius:var(--border-radius);
    border: 1px solid var(--color-mid-grey);
    padding:.5rem;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}

.dropdown-menu-select-style__link {
    display: block;
    padding:.5rem 1rem;
    font-size: 1.125rem;
    color: var(--color-text-default);
    text-decoration: none;
}