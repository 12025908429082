.breadcrumb-container + .content-block,
.hero + .content-block:not(.bg-light-grey),
.hero-small + .content-block:not(.bg-light-grey),
.slide + .content-block,
.content-block + .slide,
.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + .pimcore_area_content > .slide,
.pimcore_area_content + .pimcore_area_content > .slide {
    margin-top: calc(30rem/16);
    scroll-margin-top: calc(45rem/16);
    @media (min-width: 768px) {
        margin-top: calc(40rem/16);
        scroll-margin-top: calc(80rem/16);
    }
    @media (min-width: 1200px) {
        margin-top: calc(55rem/16);
    }
}

.content-block.bg-white,
.content-block.bg-light-grey {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
    @media (min-width: 768px) {
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
    @media (min-width: 1200px) {
        padding-top: calc(55rem/16);
        padding-bottom: calc(55rem/16);
    }
}

.content-block.bg-light-grey + .content-block.content-block.bg-light-grey {
    margin-top:0;
    border-top:.3125rem solid #fff;
    @media (min-width: 768px) {
        border-top:.625rem solid #fff;
    }
}
.pdp .content-block.bg-light-grey + .content-block.content-block.bg-light-grey {
    margin-top:0;
    border-top: none;
    @media (min-width: 768px) {
        border-top: none;
    }
}
.pdp .content-block.bg-light-grey+.content-block.bg-light-grey{
    padding-top: 0;
}

.breadcrumb-container + .content-block.bg-light-grey {
    margin-top:0;
}

#main-content:not(.main-content--cockpit) .content-block:not(.bg-light-grey):last-of-type {
    margin-bottom: calc(30rem/16);
    @media (min-width: 768px) {
        margin-bottom: calc(40rem/16);
    }
    @media (min-width: 1200px) {
        margin-bottom: calc(55rem/16);
    }
}
#main-content.main-content--cockpit .content-block:not(.bg-light-grey):last-of-type {
    padding-bottom: calc(30rem/16);
    @media (min-width: 768px) {
        padding-bottom: calc(40rem/16);
    }
    @media (min-width: 1200px) {
        padding-bottom: calc(55rem/16);
    }
}
@media (max-width: 767px) {
    #main-content .content-block {
        padding-left:1rem;
        padding-right:1rem;
    }
    #main-content .content-block .content-block {
        /*dirty fix*/
        padding-left:0;
        padding-right:0;
    }
    #main-content .fullscreen-teaser-area.content-block {
        padding-left:0;
        padding-right:0;
    }
}


/* intro */
.content-block__intro {
    margin-bottom: calc(30rem/16);

    @media (max-width: 767px) {
        margin-bottom: calc(14rem/16);
    }
}


/* buttons */
.content-block__buttons {
    margin-top: calc(20rem/16);

    @media (max-width: 767px) {
        margin-top: calc(14rem/16);
    }
}